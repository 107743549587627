import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getImageUrl } from "src/Common/common";

function SideMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [bgColorArray, setBgColorArray] = useState([
    "bg-menuHighlightColor",
    "bg-transparent",
    "bg-transparent",
    "bg-transparent",
  ]);

  useEffect(() => {
    console.log(location.pathname);

    if (location.pathname.includes("/work")) {
      setBgColorArray(["bg-menuHighlightColor", "bg-transparent", "bg-transparent", "bg-transparent"]);
    } else if (location.pathname.includes("/purchase")) {
      setBgColorArray(["bg-transparent", "bg-menuHighlightColor", "bg-transparent", "bg-transparent"]);
    } else if (location.pathname.includes("/recommendations")) {
      setBgColorArray(["bg-transparent", "bg-transparent", "bg-menuHighlightColor", "bg-transparent"]);
    } else if (location.pathname.includes("/customer")) {
      setBgColorArray(["bg-transparent", "bg-transparent", "bg-transparent", "bg-menuHighlightColor"]);
    }
  }, []);

  const workOrderClicked = () => {
    navigate("/work");
    setBgColorArray(["bg-menuHighlightColor", "bg-transparent", "bg-transparent", "bg-transparent"]);
  };

  const purchaseOrderClicked = () => {
    navigate("/purchase");
    setBgColorArray(["bg-transparent", "bg-menuHighlightColor", "bg-transparent", "bg-transparent"]);
  };

  const recommendationsClicked = () => {
    navigate("/recommendations");
    setBgColorArray(["bg-transparent", "bg-transparent", "bg-menuHighlightColor", "bg-transparent"]);
  };

  const customerClicked = () => {
    navigate("/customer");
    setBgColorArray(["bg-transparent", "bg-transparent", "bg-transparent", "bg-menuHighlightColor"]);
  };

  return (
    <div className="w-[257px] min-h-screen bg-backgroundColor relative border-r-[1px] border-borderColor">
      <div className="w-full h-[80px] flex items-center px-[30px] mt-7">
        <img className="aspect-auto w-[190px]" src={getImageUrl("/images/logos/Black logo - no background.png")} />
      </div>

      <div className="w-full  flex pl-5 pr-1 flex-col mt-5 gap-1">
        <div
          className={`flex items-center h-[24px]  gap-2 cursor-pointer px-1 rounded-md ${bgColorArray[0]}`}
          onClick={workOrderClicked}
        >
          <img className="aspect-auto w-[18px]" src={getImageUrl("/images/sidemenu/workorder.png")} />
          <div className="text-inputEmailTitle text-[12px] font-OpenSansRegular font-[600]">Work Orders</div>
        </div>

        <div
          className={`flex items-center h-[24px] gap-2 cursor-pointer px-1 rounded-md ${bgColorArray[1]}`}
          onClick={purchaseOrderClicked}
        >
          <img className="aspect-auto w-[18px]" src={getImageUrl("/images/sidemenu/purchaseorder.png")} />
          <div className="text-inputEmailTitle text-[12px] font-OpenSansRegular font-[600]">Purchase Orders</div>
        </div>

        <div
          className={`flex items-center h-[24px] gap-2 cursor-pointer px-1 rounded-md ${bgColorArray[2]}`}
          onClick={recommendationsClicked}
        >
          <img className="aspect-auto w-[18px]" src={getImageUrl("/images/sidemenu/recommendations.png")} />
          <div className="text-inputEmailTitle text-[12px] font-OpenSansRegular font-[600]">Recommendations</div>
        </div>

        <div
          className={`flex items-center h-[24px] gap-2 cursor-pointer px-1 rounded-md ${bgColorArray[3]}`}
          onClick={customerClicked}
        >
          <img className="aspect-auto w-[18px]" src={getImageUrl("/images/sidemenu/black_customer.png")} />
          <div className="text-inputEmailTitle text-[12px] font-OpenSansRegular font-[600]">Customers</div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
