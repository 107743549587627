import _ from "lodash";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { formatDate, getAvatarUrl, getImageUrl } from "src/Common/common";
import LoadMoreButton from "src/Components/Common/loadmorebutton";
import { setPONumber } from "src/Redux/Slices/HeaderSlice";
import POStatusComponent from "./Status/postatuscomponent";
import { isAdmin, handleError } from "src/Common/function";
import POService from "src/Services/API/po.service";
import ConfirmDialog from "src/Common/confirmdialog";

function Table({ data, loadMore, loadMoreButtonShown, sort, deleteSuccess }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [poArray, setPOArray] = useState([]);
  //sorting
  const [sortByDate, setSortByDate] = useState(0);
  //deleting
  const [isDeleteConfirmDialogShown, setIsDeleteConfirmDialogShown] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(0);

  useEffect(() => {
    let poData = _.cloneDeep(data);
    for (let i = 0; i < poData.length; i++) {
      console.log("poData[i].wo.assignedTechs: ", poData[i].wo.assignedTechs);
      if (poData[i].wo.assignedTechs) {
        let primaryTech = poData[i].wo.assignedTechs.find((obj) => obj.roleId === 0);
        if (primaryTech) {
          let currentIndex = poData[i].wo.assignedTechs.findIndex((obj) => obj.roleId === 0);
          poData[i].wo.assignedTechs.splice(currentIndex, 1);
          poData[i].wo.assignedTechs.splice(0, 0, primaryTech);
        }
        let techNames = poData[i].wo.assignedTechs.map((obj) => obj.user.name);
        poData[i].techNames = techNames.join(", ");
      }
    }
    setPOArray(poData);
  }, [data]);

  const deletePurchaseOrder = () => {
    POService.deleteById(poArray[indexToDelete].id)
      .then((response) => {
        console.log("delete res: ", response);
        if (response.status == 200) {
          setIsDeleteConfirmDialogShown(false);
          deleteSuccess(indexToDelete);
          toast("Purchase order deleted successfully");
        }
      })
      .catch((error) => {
        setIsDeleteConfirmDialogShown(false);
        handleError(error);
      });
  };

  return (
    <>
      <ConfirmDialog
        title="DELETE PURCHASE ORDER"
        description="Are you sure you want to delete this?"
        shown={isDeleteConfirmDialogShown}
        onConfirmClicked={deletePurchaseOrder}
        onCancelClicked={() => setIsDeleteConfirmDialogShown(false)}
      />
      <table>
        <thead>
          <tr className="border-t-[1px] border-b-[1px] border-borderColor">
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center w-[60px] h-[40px] text-titleColor text-center">
                <span>PO#</span>
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[180px] h-[40px] text-titleColor justify-center">
                <span>Vendor</span>
              </div>
            </th>
            <th className="w-min-[200px] w-full border-r-[1px] ">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor min-w-[200px] ">
                Description
              </div>
            </th>
            <th className="w-min-[200px] w-full border-r-[1px]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[150px] h-[40px] text-titleColor justify-left">
                Issued For
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-1 w-[70px] h-[40px] text-titleColor">
                Parent WO#
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[200px] h-[40px] text-titleColor justify-left">
                Customer
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[160px] h-[40px] text-titleColor">
                Issued By
              </div>
            </th>

            <th
              onClick={() => {
                if (sortByDate === 1) {
                  setSortByDate(0);
                  sort({ date: 0 });
                } else if (sortByDate === 0) {
                  setSortByDate(1);
                  sort({ date: 1 });
                }
              }}
              className="w-full border-r-[1px] border-borderColor cursor-pointer flex items-center"
            >
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center w-[120px] h-[40px] text-titleColor ">
                Date Added
              </div>
              {sortByDate === 0 ? <IoIosArrowRoundUp /> : <IoIosArrowRoundDown />}
            </th>

            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[100px] h-[40px] text-titleColor">
                Status
              </div>
            </th>
            {/* <th className="border-r-[1px] border-borderColor">
            <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[120px] h-[40px] text-titleColor">
              Type
            </div>
          </th> */}
            {/* <th className="border-r-[1px] border-borderColor">
            <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-2 w-[120px] h-[40px] text-titleColor">
              Category
            </div>
          </th> */}
          </tr>
        </thead>
        <tbody>
          {poArray.map((order, index) => (
            <tr
              key={index}
              onClick={() => {
                dispatch(setPONumber(String(order.number)));
                navigate("/purchase/" + String(order.number), {
                  state: { order: order },
                });
              }}
              className="border-b-[1px] border-borderColor h-[40px] cursor-pointer"
            >
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[5px] py-[5px] text-center text-[11px] font-[400] col-span-1">
                  {order.number}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[5px] py-[5px] text-center text-[11px] font-[400] col-span-1">
                  {order.vendor}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor ">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] font-[400]">
                  {order.description}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-left text-[11px] font-[400] col-span-1 word-break-break-word">
                  {order.issuedUser?.name}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor ">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-center text-[11px] font-[400]">
                  {order.wo.number}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-left text-[11px] font-[400] word-break-break-word">
                  {order.wo.customer.company}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                {order.issuedBy && (
                  <div className="flex items-center gap-1">
                    <img
                      src={getAvatarUrl(order.issuedBy.avatar)}
                      className="w-[20px] rounded-[10px] cursor-pointer ml-2"
                    />
                    <span className="font-OpenSansRegular text-[12px] text-titleColor">{order.issuedBy.name}</span>
                  </div>
                )}
              </td>
              <td className="border-r-[1px]  border-borderColor ">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] font-[400] text-center">
                  {formatDate(order.createdAt)}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="  px-[10px] py-[5px] flex items-center gap-3">
                  <POStatusComponent value={order.status} />
                  {isAdmin() && (
                    <img
                      onClick={(event) => {
                        setIsDeleteConfirmDialogShown(!isDeleteConfirmDialogShown);
                        setIndexToDelete(index);
                        event.stopPropagation();
                      }}
                      src={getImageUrl("/images/common/trash.png")}
                      className="cursor-pointer"
                    />
                  )}
                </div>
              </td>
              {/* <td className="border-r-[1px]  border-borderColor">   
              <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] font-[400]">{order.category}</div>
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="w-full mt-[10px] mb-[10px]">{loadMoreButtonShown && <LoadMoreButton onClicked={loadMore} />}</div>
    </>
  );
}

export default Table;
