import { useEffect, useState } from "react";
import UserService from "src/Services/API/user.service";
import TimezoneDropdown from "./timezonedropdown";

function AddModal({ branch, onAddClicked, onUpateClicked, onCancelClicked }) {
  const [name, setName] = useState("");
  const [poStartNumber, setPoStartNumber] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [timezone, setTimezone] = useState(null);

  const [yesButtonTitle, setYesButtonTitle] = useState("ADD");

  useEffect(() => {
    if (branch) {
      setName(branch.name);
      setPoStartNumber(branch.poStartNumber);
      setAddress(branch.address);
      setPhone(branch.phone);
      setTimezone(branch.timezone);

      setYesButtonTitle("UPDATE");
    } else {
      setYesButtonTitle("ADD");
    }
  }, []);

  const addClicked = () => {
    console.log("🚀 ~ addClicked ~ data.timezone:", timezone);
    if (branch) {
      branch.name = name;
      branch.poStartNumber = poStartNumber;
      branch.address = address;
      branch.phone = phone;
      branch.timezone = timezone;

      onUpateClicked(branch);
    } else {
      const data = {
        name,
        poStartNumber,
        address,
        phone,
        timezone,
      };
      onAddClicked(data);
    }
  };

  const cancelClicked = () => {
    onCancelClicked();
  };

  return (
    <div
      onClick={onCancelClicked}
      className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center"
    >
      <div onClick={(e) => e.stopPropagation()} className="w-[448px]  bg-white rounded-[6px] p-[18px] flex flex-col">
        <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">ADD BRANCH</p>

        <div className="flex flex-col justify-between h-full">
          <div className="h-[60px] w-full">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Name</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={name}
                type="text"
                onChange={(e) => setName(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Enter branch name"
              />
            </div>
          </div>

          <div className="h-[60px] w-full mt-3">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">PO Start Number</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={poStartNumber}
                type="number"
                onChange={(e) => setPoStartNumber(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Enter branch name"
              />
            </div>
          </div>

          <div className="h-[60px] w-full mt-3">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Address</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={address}
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Enter branch address"
              />
            </div>
          </div>

          <div className="h-[60px] w-full mt-3">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Phone</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={phone}
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Enter branch phone"
              />
            </div>
          </div>

          <div className="h-[60px] w-full mt-3">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Timezone</p>
            <TimezoneDropdown timezone={timezone} timezoneSelected={setTimezone} />
          </div>

          <div className=" w-full mt-3">
            <div className="flex items-center justify-between gap-4">
              <div
                onClick={cancelClicked}
                className="w-[50%] h-[30px] text-[10px] rounded-[6px] border-[1px] border-subTitleColor flex items-center justify-center text-subTitleColor cursor-pointer"
              >
                CANCEL
              </div>
              <div
                onClick={addClicked}
                className="w-[50%] h-[30px] text-[10px] rounded-[6px] flex items-center justify-center bg-blueColor text-white cursor-pointer"
              >
                {yesButtonTitle}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddModal;
