import { useEffect, useState } from "react";

import _ from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  API_URL,
  downloadFile,
  downloadImage,
  handleError,
  isAccountant,
  isAdmin,
  isClericalOver,
} from "src/Common/function";
import Button from "src/Components/Button";
import CreateButton from "src/Components/CreateButton";
import DownloadButton from "src/Components/DownloadButton";
import UserService from "src/Services/API/user.service";
import WOService from "src/Services/API/wo.service";
import CreateModal from "./Components/createmodal";
import WOFitlerView from "src/Components/filter/WOFilterView";
import Table from "./Components/table";
import BranchBar from "src/Components/branchbar/BranchBar";
import moment from "moment-timezone";
import { convertToArizonaTime } from "src/Common/common";

function WO() {
  const keyword = useSelector((state) => state.Keyword.keyword);
  const branch = useSelector((state) => state.Branch.branch);
  const user = UserService.getCurrentUser();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [orderArray, setOrderArray] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMoreButtonShown, setLoadMoreButtonShown] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [criteria, setCriteria] = useState(null);
  const [sort, setSort] = useState(null);

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setPageNumber(0);
    getAllWorkOrder([], 0);
    console.error("🚀 ~ getAllWorkOrders ~ branchId:", branch);
  }, [keyword, criteria, sort, branch]);

  const getAllWorkOrder = (previousOrderArray, pNumber) => {
    WOService.getAllWorkOrders(user.id, keyword, pNumber, criteria, sort, branch)
      .then((response) => {
        if (response.status == 200) {
          let temp = [...previousOrderArray, ...response.data.orders];
          setOrderArray(temp);
          if (temp.length >= response.data.totalCount) {
            setLoadMoreButtonShown(false);
          } else {
            setLoadMoreButtonShown(true);
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const saveWO = (data) => {
    WOService.createWorkOrder(data)
      .then((response) => {
        if (response.status === 201) {
          setIsCreateOpen(false);
          toast("Work order created successfully");
          getAllWorkOrder([], 0);
        } else {
          toast("something went wrong!");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleLoadMore = () => {
    getAllWorkOrder(orderArray, pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  const downloadExcelFile = async () => {
    setIsDownloading(true);
    try {
      await WOService.generateExcelFile(user.id, keyword, criteria, sort, branch);
      await downloadFile(`${API_URL}/rscs/reports/${user.id}/WOs_report.xlsx`, "WOs_report.xlsx");
      setIsDownloading(false);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="w-full bg-red bg-backgroundColor">
      <CreateModal
        order={null}
        onSubmitClicked={saveWO}
        shown={isCreateOpen}
        onCancelClicked={() => setIsCreateOpen(false)}
      />
      <div className="px-7 py-2">{(isAdmin() || isAccountant()) && <BranchBar />}</div>
      <div className="w-full flex items-center gap-8 justify-end py-[20px] px-[18px]">
        {branch !== 0 && (
          <Button onClicked={() => setFilterOpen(!filterOpen)} title="Filter" isHighlighted={filterOpen} />
        )}
        {branch !== 0 && filterOpen && <DownloadButton isLoading={isDownloading} onClicked={downloadExcelFile} />}
        {branch !== 0 && (isClericalOver() || isAccountant()) && (
          <CreateButton onClicked={() => setIsCreateOpen(true)} title="Create" />
        )}
      </div>
      <div className="px-7">
        {filterOpen && branch !== 0 && <WOFitlerView apply={(data) => setCriteria(data)} />}
        <Table
          data={orderArray}
          loadMore={handleLoadMore}
          loadMoreButtonShown={loadMoreButtonShown}
          deleteSuccess={(index) => {
            let temp = _.cloneDeep(orderArray);
            temp.splice(index, 1);
            setOrderArray(temp);
          }}
          sort={(data) => setSort(data)}
        />
      </div>
    </div>
  );
}

export default WO;
