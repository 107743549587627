import { toast } from "react-toastify";
import axios from "axios";

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validatePassword = (password) => {
  let response = { success: true, message: "" };
  if (password.length < 6) {
    response.success = false;
    response.message = "Password must be at least 6 letters";
  }

  return response;
};

export const API_URL =
  process.env.REACT_APP_ENV == "production"
    ? "https://makani.services"
    : process.env.REACT_APP_ENV == "staging"
    ? "https://test.makani.services"
    : "http://localhost:5000";
// export const API_URL = "https://makani.services";

export const isAuthenticatedWithToken = () => {
  const token = localStorage.getItem("accessToken"); // or sessionStorage.getItem('jwtToken');

  if (token) {
    // try {
    //   const { exp } = jwt_decode(token);
    //   return exp > Date.now() / 1000;
    // } catch (error) {
    //   return false;
    // }
    return true;
  }
  return false;
};

export const handleError = (error) => {
  console.log("error: ", error);
  if (error.response) {
    toast(error.response.data.message);
  } else {
    toast("An error occured");
  }
};

export const limitString = (str, maxLength) => {
  if (!str) return "";
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  } else {
    return str;
  }
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const base64String = event.target.result.split(",")[1]; // Extracting only the Base64 string
      console.log("Base64 Encoded File:", base64String);
      resolve(base64String);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const downloadImage = (url, fileName) => {
  const img = new Image();
  img.crossOrigin = "Anonymous";

  img.onload = function () {
    const canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(this, 0, 0);

    const imageURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imageURL;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  img.src = url;
};

export const downloadPdf = async (pdfURL, fileName) => {
  try {
    const response = await axios.get(pdfURL, {
      responseType: "blob",
    });

    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(pdfBlob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", fileName);
    document.body.appendChild(tempLink);
    tempLink.click();
    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};

export async function downloadFile(url, filename) {
  const response = await fetch(url);
  const blob = await response.blob();

  const urlBlob = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = urlBlob;
  a.download = filename || "download";

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(urlBlob); // Clean up
}

export const getUserNameArray = (userIdArray, userArray) => {
  if (userIdArray.length === 0) return "";
  let temp = [];
  for (let userId of userIdArray) {
    let user = userArray.find((item) => item.id == userId);
    if (user) {
      temp.push(user);
    }
  }
  const names = temp.map((user) => user.name);
  return names.join(", ");
};

export const getAssignedTechsNameArray = (assignedTechs) => {
  if (assignedTechs) {
    let primaryTech = assignedTechs.find((obj) => obj.roleId === 0);
    if (primaryTech) {
      let primaryTechIndex = assignedTechs.findIndex((obj) => obj.roleId === 0);
      assignedTechs.splice(primaryTechIndex, 1);
      assignedTechs.splice(0, 0, primaryTech);
    }
    let techNames = assignedTechs.map((obj) => obj.user.name);
    techNames = techNames.join(", ");
    return techNames;
  } else {
    return "Waiting for Assignment";
  }
};

export const isAdmin = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user.roles[0].id === 1;
};

export const isManagerOver = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user.roles[0].id <= 2;
};

export const isClericalOver = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user.roles[0].id <= 3;
};

export const isAccountant = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user.roles[0].id === 100;
};

export const isTechnician = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user.roles[0].id === 4;
};
