import api from "./api";
import UserService from "./user.service";
import { isAccountant, isAdmin } from "src/Common/function";

const getPurchaseOrder = (userId, keyword, pageNumber, criteria, sort, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId;
  if (isAdmin() || isAccountant()) {
    branchId = branch;
  } else {
    branchId = currentUser.branches[0].id;
  }
  return api.get("/api/po/get", {
    params: { userId, branchId, keyword, pageNumber, criteria, sort },
  });
};

const getPurchaseOrdersByWoId = (woId) => {
  return api.get("/api/po/get_all", { params: { woId: woId } });
};

const getPurchaseOrdersByPoId = (poId) => {
  return api.get("/api/po/getOrderById", { params: { poId: poId } });
};

const savePurchaseOrder = (data) => {
  return api.post("/api/po/create", data);
};

const update = (data) => {
  return api.post("/api/po/update", data);
};

const uploadFile = (file, orderId) => {
  const formData = new FormData();
  formData.append("id", orderId);
  formData.append("file", file);

  return api.post("/api/po/attach", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

const attachFile = (data) => {
  return api.post("/api/po/upload_attachment", data);
};

// const changeStatusWorkOrder = (data) => {
//   return api.post("/api/wo/change_status", data);
// };

// const assignTechs = (data) => {
//   return api.post("/api/wo/assign_tech", data);
// };

const generatePOReceipt = (poId) => {
  return api.get("/api/po/generate-po-receipt", { params: { poId: poId } });
};

const deleteAttachment = (poId, index) => {
  return api.get("/api/po/delete_attachment", { params: { poId, index } });
};

const generateExcelFile = (userId, keyword, criteria, sort, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.get("/api/po/generate_excel_file", { params: { userId, branchId, keyword, criteria, sort } });
};

const getAllOrderNumberWithKeyword = (keyword, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin() && !isAccountant()) {
    branchId = currentUser.branches[0].id;
  }
  console.log("🚀 ~ getAllOrderNumberWithKeyword ~ branchId:", branch, branchId);
  return api.get("/api/po/getAllOrderNumberWithKeyword", { params: { keyword, branchId } });
};

const deleteById = (id) => {
  return api.get("/api/po/deleteByID", { params: { id } });
};

const POService = {
  savePurchaseOrder,
  getPurchaseOrdersByWoId,
  uploadFile,
  update,
  getPurchaseOrder,
  getPurchaseOrdersByPoId,
  attachFile,
  generatePOReceipt,
  deleteAttachment,
  generateExcelFile,
  getAllOrderNumberWithKeyword,
  deleteById,
};

export default POService;
