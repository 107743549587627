import { current } from "@reduxjs/toolkit";
import { isAccountant, isAdmin } from "src/Common/function";
import api from "./api";
import UserService from "./user.service";

//This function is for admin
const getAll = (keyword) => {
  return api.get("/api/vendor/get_all", { params: { keyword } });
};

//this function can be used when creating a purchase order
const getAllByBranch = (branchId) => {
  return api.get("/api/vendor/get_all", { params: { branchId } });
};

const saveVendor = (vendor) => {
  // let currentUser = UserService.getCurrentUser();
  // let branchId = branch;
  // if (currentUser.branches.length === 1) {
  //   branchId = currentUser.branches[0].id;
  // }
  // vendor["branch"] = branchId;
  return api.post("/api/vendor/save", vendor);
};

const deleteVendor = (id) => {
  return api.post("/api/vendor/delete", { vendorId: id });
};

const updateVendor = (vendor) => {
  return api.post("/api/vendor/update", { ...vendor });
};

const VendorService = {
  getAll,
  getAllByBranch,
  saveVendor,
  deleteVendor,
  updateVendor,
};

export default VendorService;
