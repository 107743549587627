import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import BlueButton from "src/Components/Common/bluebutton";
import CancelButton from "src/Components/Common/cancelbutton";
import RoleService from "src/Services/API/role.service";
import UserService from "src/Services/API/user.service";
import RoleDropDown from "./roledropdown";
import ConfirmDialog from "src/Common/confirmdialog";
import { formatDate, getImageUrl } from "src/Common/common";
import InviteModal from "../Invite/InviteModal/invitemodal";
import InviteService from "src/Services/API/invite.service";
import BranchService from "src/Services/API/branch.service";
import { setBranch } from "src/Redux/Slices/BranchSlice";
import BranchDropDown from "./branchdropdown";

function Manage({ branch }) {
  const navigate = useNavigate();
  const [roleArray, setRoleArray] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [branchArray, setBranchArray] = useState([]);
  const [index, setIndex] = useState(0);
  const [isDeleteConfirmDialogShown, setIsDeleteConfirmDialogShown] = useState(false);
  const [roleChangedUserArray, setRoleChangedUserArray] = useState([]);

  useEffect(() => {
    BranchService.getAll()
      .then((response) => {
        console.log("🚀 ~ BranchService.getAll ~ response:", response.data);
        setBranchArray(response.data);
      })
      .catch((error) => {
        toast("An error occured");
      });
  }, []);

  useEffect(() => {
    RoleService.getAll()
      .then((response) => {
        console.log("Role list: ", response);
        if (response.status == 200) {
          setRoleArray(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
    getAllUsers();
  }, [branch]);

  const getAllUsers = () => {
    UserService.getAll(branch)
      .then((response) => {
        console.log("User list: ", response);
        if (response.status == 200) {
          let users = response.data.filter((user) => user.isEnabled === true);
          for (let i = 0; i < users.length; i++) {
            const date = new Date(users[i].createdAt);
            users[i].formattedCreatedAt = formatDate(date);
          }
          setUserArray(users);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
  };

  const deleteUser = () => {
    UserService.deleteUser(userArray[index].id)
      .then((response) => {
        if (response.status == 201) {
          toast("User archived successfully");
          getAllUsers();
        }
        setIsDeleteConfirmDialogShown(false);
      })
      .catch((error) => {
        setIsDeleteConfirmDialogShown(false);

        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
  };

  const roleChange = (user, newRole) => {
    user.roles = [newRole];
    console.log("🚀 ~ roleChange ~ user:", user, newRole);

    UserService.updateUser(user)
      .then((response) => {
        if (response.status == 201) {
          toast("User role changed successfully");
        } else {
          toast("Something went wrong!");
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });

    // user.roles = [newRole];
    // let tempArray = [...roleChangedUserArray];
    // tempArray.push(user);
    // setRoleChangedUserArray(tempArray);
  };

  // const saveChanges = () => {
  //   console.log("roleChangedUserArray: ", roleChangedUserArray);
  //   UserService.updateUsers(roleChangedUserArray)
  //     .then((response) => {
  //       if (response.status == 201 && response.data == true) {
  //         toast("Saved successfully");
  //         navigate(-1);
  //       } else {
  //         toast("Something went wrong!");
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         toast(error.response.data.message);
  //       } else {
  //         toast("An error occured");
  //       }
  //     });
  // };

  const branchChange = (user, branch, checked) => {
    let userData;
    if (checked) {
      //add branch
      userData = { ...user, branches: [...user.branches, branch] };
    } else {
      //remove branch
      const branchArray = user.branches.filter((item) => item.id !== branch.id);
      userData = { ...user, branches: branchArray };
    }

    UserService.updateUser(userData)
      .then((response) => {
        if (response.status == 201) {
          getAllUsers();
        } else {
          toast("Something went wrong!");
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
  };

  return (
    <div>
      <ConfirmDialog
        title="ARCHIVE USER"
        description="Are you sure you want to archive this user?"
        shown={isDeleteConfirmDialogShown}
        onConfirmClicked={deleteUser}
        onCancelClicked={() => setIsDeleteConfirmDialogShown(false)}
      />

      <table className="w-full mt-3">
        <thead>
          <tr className="border-t-[1px] border-b-[1px] border-borderColor">
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-4 h-[40px] text-titleColor">
                <span>Name</span>
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-4 h-[40px] text-titleColor">
                <span>Email</span>
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor w-[200px]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Role
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor w-[250px]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Branch
              </div>
            </th>
            <th className="w-[170px]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-2 h-[40px] text-titleColor">
                Added
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {userArray.map((user, i) => (
            <tr key={i} className="border-b-[1px] border-borderColor h-[40px]">
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {user.name}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {user.email}
                </div>
              </td>

              <td className="border-r-[1px] border-borderColor relative">
                <RoleDropDown user={user} roles={roleArray} roleClicked={roleChange} />
              </td>
              <td className="border-r-[1px] border-borderColor relative">
                <BranchDropDown user={user} branches={branchArray} onChange={branchChange} />
              </td>

              <td>
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1 flex items-center justify-between">
                  <span>{user.formattedCreatedAt}</span>
                  <img
                    onClick={() => {
                      setIsDeleteConfirmDialogShown(!isDeleteConfirmDialogShown);
                      setIndex(i);
                    }}
                    src={getImageUrl("/images/common/trash.png")}
                    className="cursor-pointer"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Manage;
