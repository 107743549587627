import { useState } from "react";
import { useSelector } from "react-redux";
import { isAdmin } from "src/Common/function";
import BranchBar from "src/Components/branchbar/BranchBar";
import Invite from "./Invite/invite";
import Manage from "./Manage/manage";
import Archive from "./Archive/archive";

const Team = () => {
  const branch = useSelector((state) => state.Branch.branch);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabColorArray, setTabColorArray] = useState(["text-blueColor", "text-titleColor"]);

  const tabClicked = (index) => {
    let temp = [...tabColorArray];
    for (let i = 0; i < temp.length; i++) {
      temp[i] = "text-titleColor";
    }
    temp[index] = "text-blueColor";
    setTabColorArray(temp);

    setTabIndex(index);
  };

  return (
    <div>
      <div className="px-7 py-2">{isAdmin() && <BranchBar />}</div>
      <div className="flex items-center gap-3">
        <span
          onClick={() => tabClicked(0)}
          className={`font-OpenSansRegular text-[16px] font-[600] cursor-pointer ${tabColorArray[0]}`}
        >
          Manage
        </span>
        <div className="w-[1px] h-[16px] bg-gray-400" />
        <span
          onClick={() => tabClicked(1)}
          className={`font-OpenSansRegular text-[16px] font-[600] cursor-pointer ${tabColorArray[1]}`}
        >
          Invite
        </span>
        <div className="w-[1px] h-[16px] bg-gray-400" />
        <span
          onClick={() => tabClicked(2)}
          className={`font-OpenSansRegular text-[16px] font-[600] cursor-pointer ${tabColorArray[2]}`}
        >
          Archive
        </span>
      </div>
      {tabIndex === 0 ? (
        <Manage branch={branch} />
      ) : tabIndex === 1 ? (
        <Invite branch={branch} />
      ) : (
        <Archive branch={branch} />
      )}
    </div>
  );
};

export default Team;
