import React, { useState } from "react";
import AdHoc from "./Ad-Hoc/AdHoc";
import BranchButton from "src/Components/branchbar/BranchButton";
import AutoReport from "./Auto/AutoReport";
import BranchBar from "src/Components/branchbar/BranchBar";
import { isAccountant, isAdmin, isManagerOver } from "src/Common/function";

const ReportCenter = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div>
      <div className="px-7 py-2">{isAdmin() && <BranchBar allEnabled={false} />}</div>
      <div className="flex items-center gap-3">
        <BranchButton
          title={"Ad-Hoc Reports"}
          index={0}
          clicked={setSelectedIndex}
          selected={0 === selectedIndex ? true : false}
        />
        {(isManagerOver() || isAccountant()) && (
          <BranchButton
            title={"Auto-Generated Reports"}
            index={1}
            clicked={setSelectedIndex}
            selected={1 === selectedIndex ? true : false}
          />
        )}
      </div>
      {selectedIndex === 0 ? <AdHoc /> : <AutoReport />}
    </div>
  );
};

export default ReportCenter;
