import api from "./api";
import UserService from "./user.service";

const getAll = (count) => {
  let currentUser = UserService.getCurrentUser();
  return api.get("/api/keyword/get_all", { params: { userId: currentUser.id, count } });
};

const save = (keyword) => {
  let currentUser = UserService.getCurrentUser();
  return api.post("/api/keyword/save", { userId: currentUser.id, keyword });
};

const KeywordService = {
  getAll,
  save,
};

export default KeywordService;
