import { useEffect, useState, useRef } from "react";
import { getImageUrl } from "src/Common/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setKeyword } from "src/Redux/Slices/KeywordSlice";
import { useDebounce } from "use-debounce";
import { TiDeleteOutline } from "react-icons/ti";
import { VscHistory } from "react-icons/vsc";
import { IoIosSearch } from "react-icons/io";
import KeywordService from "src/Services/API/keyword.service";
import WOService from "src/Services/API/wo.service";
import CustomerService from "src/Services/API/customer.service";
import { set } from "lodash";
import POService from "src/Services/API/po.service";
import VendorService from "src/Services/API/vendor.service";

function SearchBox() {
  const dispatch = useDispatch();
  const location = useLocation();
  const branch = useSelector((state) => state.Branch.branch);
  const [keywordString, setKeywordString] = useState("");
  const [historyArray, setHistoryArray] = useState([]);
  const [suggestionArray, setSuggestionArray] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const containerRef = useRef(null);
  const itemRefs = useRef([]);

  const keywordChanged = async (e) => {
    const string = e.target.value;
    setKeywordString(string);
    if (string.length === 0) {
      dispatch(setKeyword(""));
    }

    setSuggestionArray([]);
    if (location.pathname.includes("/work") || location.pathname.includes("/recommendations")) {
      //get suggestions for work order numbers
      let res = await WOService.getAllOrderNumberWithKeyword(string, branch);
      let woNumbers = res.data;
      //get suggestions for customers
      res = await CustomerService.getAll(branch, string);
      let customers = res.data.map((item) => item.company);

      let temp = [...woNumbers, ...customers];
      if (temp.length > 0) {
        setIsDropdownOpen(true);
      } else {
        setIsDropdownOpen(false);
      }
      setSuggestionArray([...woNumbers, ...customers]);
    } else if (location.pathname.includes("/purchase")) {
      let res = await POService.getAllOrderNumberWithKeyword(string, branch);
      let poNumbers = res.data;
      res = await VendorService.getAll(string);
      let vendors = res.data.map((item) => item.name);
      res = await WOService.getAllOrderNumberWithKeyword(string, branch);
      let woNumbers = res.data;
      res = await CustomerService.getAll(branch, string);
      let customers = res.data.map((item) => item.company);

      let temp = [...poNumbers, ...vendors, ...woNumbers, ...customers];
      if (temp.length > 0) {
        setIsDropdownOpen(true);
      } else {
        setIsDropdownOpen(false);
      }
      setSuggestionArray(temp);
    } else if (location.pathname.includes("/customer")) {
      let res = await CustomerService.getAll(branch, string);
      let companies = res.data.map((item) => item.company);

      let temp = [...companies];
      if (temp.length > 0) {
        setIsDropdownOpen(true);
      } else {
        setIsDropdownOpen(false);
      }
      setSuggestionArray(temp);
    } else if (location.pathname.includes("/settings/vendor")) {
      let res = await VendorService.getAll(string);
      let vendors = res.data.map((item) => item.name);

      let temp = [...vendors];
      if (temp.length > 0) {
        setIsDropdownOpen(true);
      } else {
        setIsDropdownOpen(false);
      }
      setSuggestionArray(temp);
    }
  };

  useEffect(() => {
    setKeywordString("");
    dispatch(setKeyword(""));
    setFocusedIndex(-1);
  }, [location.pathname]);

  const handleKeyDown = (e) => {
    let string = e.target.value;
    if (e.key === "Enter") {
      if (focusedIndex >= 0) {
        if (string.length > 0) {
          setKeywordString(suggestionArray[focusedIndex]);
          dispatch(setKeyword(suggestionArray[focusedIndex]));
          KeywordService.save(suggestionArray[focusedIndex]);
        } else {
          setKeywordString(historyArray[focusedIndex]);
          dispatch(setKeyword(historyArray[focusedIndex]));
          KeywordService.save(historyArray[focusedIndex]);
        }
        setIsDropdownOpen(false);
      } else {
        if (string.length > 0) {
          dispatch(setKeyword(string));
          KeywordService.save(string);
        }
      }
    } else if (e.key === "ArrowDown") {
      if (string.length > 0 && focusedIndex < suggestionArray.length - 1) {
        setFocusedIndex(focusedIndex + 1);
        scrollToItem(focusedIndex + 1);
      }
      if (string.length === 0 && focusedIndex < historyArray.length - 1) {
        setFocusedIndex(focusedIndex + 1);
        scrollToItem(focusedIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (focusedIndex > 0) {
        setFocusedIndex(focusedIndex - 1);
        scrollToItem(focusedIndex - 1);
      }
    }
  };

  const handleFocus = async () => {
    let res = await KeywordService.getAll(10);
    let keywords = res.data;
    if (keywords.length > 0) {
      setHistoryArray(keywords);
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  const handleKeywordClick = (keyword) => {
    setKeywordString(keyword);
    dispatch(setKeyword(keyword));
    setIsDropdownOpen(false);
    KeywordService.save(keyword);
  };

  const scrollToItem = (index) => {
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  return (
    <div className="flex items-center gap-1 w-[300px] h-[30px] rounded-md border-[1px] px-2 relative">
      <img src={getImageUrl("/images/common/search.png")} />
      <input
        value={keywordString}
        type="text"
        onChange={keywordChanged}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={() => {
          setIsDropdownOpen(false);
          setFocusedIndex(-1);
        }}
        className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium bg-backgroundColor"
        placeholder="Search"
      />
      <div
        onClick={() => {
          dispatch(setKeyword(""));
          setKeywordString("");
        }}
        className="cursor-pointer p-1"
      >
        <TiDeleteOutline color="gray" size={14} />
      </div>
      {isDropdownOpen && (
        <ul
          ref={containerRef}
          className="absolute z-10 left-0 top-[30px] w-full bg-white border rounded-md shadow-lg max-h-[300px] overflow-y-auto"
        >
          {keywordString.length > 0
            ? suggestionArray.map((suggestion, index) => (
                <li
                  ref={(el) => (itemRefs.current[index] = el)}
                  key={index}
                  onMouseDown={() => handleKeywordClick(suggestion)}
                  className={`px-3 py-1 cursor-pointer font-OpenSansRegular text-[12px] flex items-center gap-2 ${
                    index === focusedIndex ? "bg-gray-200" : "hover:bg-gray-200"
                  }`}
                >
                  <div>
                    <IoIosSearch size={16} />
                  </div>
                  {suggestion}
                </li>
              ))
            : historyArray.map((keyword, index) => (
                <li
                  ref={(el) => (itemRefs.current[index] = el)}
                  key={index}
                  onMouseDown={() => handleKeywordClick(keyword)}
                  // className="px-3 py-1 cursor-pointer hover:bg-gray-200 font-OpenSansRegular text-[12px] flex items-center gap-2"
                  className={`px-3 py-1 cursor-pointer font-OpenSansRegular text-[12px] flex items-center gap-2 ${
                    index === focusedIndex ? "bg-gray-200" : "hover:bg-gray-200"
                  }`}
                >
                  <VscHistory size={13} />
                  {keyword}
                </li>
              ))}
        </ul>
      )}
    </div>
  );
}

export default SearchBox;
