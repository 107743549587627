import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { useLocation } from "react-router-dom";

import SearchBox from "src/Components/SearchBox";
import UserService from "src/Services/API/user.service";
import { getAvatarUrl, getImageUrl } from "src/Common/common";
import AuthService from "src/Services/API/auth.service";
import { setAuthState } from "src/Redux/Slices/AuthSlice";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const keywordChanged = () => {};
  const [avatar, setAvatar] = useState(null);
  const [name, setName] = useState("");
  const [menuShown, setMenuShown] = useState(false);
  const [title, setTitle] = useState("");

  const woNumber = useSelector((state) => state.Header.woNumber);
  const poNumber = useSelector((state) => state.Header.poNumber);
  const customerName = useSelector((state) => state.Header.customerName);

  useEffect(() => {
    setAvatar(getAvatarUrl(UserService.getCurrentUser().avatar));
    setName(UserService.getCurrentUser().name);
  }, []);

  const settingClicked = () => {
    navigate("/settings/account");
  };

  const signOut = () => {
    dispatch(setAuthState(false));
    AuthService.logout();
    navigate("/");
  };

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ location.pathname:", location.pathname);
    if (location.pathname === "/work") {
      setTitle("Work Orders");
    } else if (location.pathname.includes("/work/")) {
      setTitle("Work Order #" + woNumber);
    } else if (location.pathname.includes("/purchase")) {
      setTitle("Purchase Orders");
    } else if (location.pathname.includes("/purchase/")) {
      setTitle("Purchase Order #" + poNumber);
    } else if (location.pathname.includes("/recommendations")) {
      setTitle("Recommendations");
    } else if (location.pathname.includes("/setting")) {
      setTitle("Settings");
    } else if (location.pathname.includes("/customer")) {
      if (location.pathname.includes("/customer/wo")) {
        setTitle(`Work Orders for ${customerName}`);
      } else {
        setTitle("Customers");
      }
    } else if (location.pathname.includes("/serviceticket")) {
      setTitle("Service Ticket");
    }
  }, [location, customerName, woNumber, poNumber]);

  return (
    <div className="min-h-[60px] flex items-center relative border-b-[1px] border-borderColor bg-backgroundColor">
      <span className="font-OpenSansRegular font-[700] text-[24px] ml-5 text-titleColor ">{title}</span>
      <div className="absolute right-7 bottom-2 flex items-center gap-2">
        <SearchBox />
        <img
          onClick={settingClicked}
          src={getImageUrl("/images/common/setting.png")}
          className="w-[13px] cursor-pointer"
        />
        <div onClick={() => setMenuShown(true)} className="flex items-center gap-2 cursor-pointer relative">
          <img src={avatar} className="w-[20px] rounded-[10px]  ml-2" />
          <span className="font-OpenSansRegular font-[400] text-[10px] text-subTitleColor ">{name}</span>

          <OutsideClickHandler
            onOutsideClick={() => {
              setMenuShown(false);
            }}
          >
            {menuShown && (
              <div
                onClick={signOut}
                className="flex flex-col items-center py-1 justify-between absolute top-[25px] left-[8px] w-[60px] h-[26px] border-[1px] rounded-[6px] border-borderColor bg-white z-10"
              >
                <span className="font-OpenSansRegular font-[400] text-[10px] text-subTitleColor ">Log out</span>
              </div>
            )}
          </OutsideClickHandler>
        </div>
      </div>
    </div>
  );
}

export default Header;
