import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import BranchBar from "src/Components/branchbar/BranchBar";
import { isAccountant, isAdmin, isClericalOver } from "src/Common/function";
import AddModal from "./addmodal";
import CustomerService from "src/Services/API/customer.service";
import CustomerTable from "./customertable";
import UserService from "src/Services/API/user.service";
import { formatDate } from "src/Common/common";

function Customer() {
  const branch = useSelector((state) => state.Branch.branch);
  const keyword = useSelector((state) => state.Keyword.keyword);
  const currentUser = UserService.getCurrentUser();
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [editingCustomer, setEditingCustomer] = useState(null);

  useEffect(() => {
    getCustomers();
  }, [branch, keyword]);

  const getCustomers = async () => {
    try {
      const response = await CustomerService.getAll(branch, keyword);
      if (response.status == 200) {
        let tempArray = [];
        for (let item of response.data) {
          item.formattedCreatedAt = formatDate(item.createdAt, "MM/DD/YYYY");
          tempArray.push(item);
        }
        setCustomers(tempArray);
      } else {
        toast("Something went wrong!");
      }
    } catch (error) {
      console.log("error: ", error);
      toast(error.response.data.message);
    }
  };

  const addButtonClicked = () => {
    setIsAdd(true);
  };

  const addClicked = (data) => {
    CustomerService.saveCustomer(data, branch)
      .then((response) => {
        console.log(response);
        if (response.status == 201) {
          toast("Customer saved successfully");
          getCustomers();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Customer save error: ", error);
        toast(error.response.data.message);
      });
    setIsAdd(false);
  };

  const deleteCustomer = (index) => {
    CustomerService.deleteCustomer(customers[index].id)
      .then((response) => {
        console.log(response);
        if (response.status == 201 && response.data == true) {
          toast("Customer deleted successfully");
          getCustomers();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Customer save error: ", error);
        toast(error.response.data.message);
      });
  };

  const updateCustomer = (index) => {
    setIsEdit(true);
    setEditingCustomer(customers[index]);
  };

  const updateClicked = (customer) => {
    CustomerService.updateCustomer(customer)
      .then((response) => {
        console.log(response);
        if (response.status == 201) {
          toast("Customer updated successfully");
          getCustomers();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Customer save error: ", error);
        toast(error.response.data.message);
      });
    setIsEdit(false);
  };

  return (
    <div className="w-full h-full bg-red bg-backgroundColor px-3">
      <div className="px-7 py-2">{(isAdmin() || isAccountant()) && <BranchBar />}</div>
      {isAdd && <AddModal onAddClicked={addClicked} onCancelClicked={() => setIsAdd(false)} customer={null} />}
      {isEdit && (
        <AddModal onUpateClicked={updateClicked} onCancelClicked={() => setIsEdit(false)} customer={editingCustomer} />
      )}
      {branch !== 0 && (isClericalOver() || isAccountant()) && (
        <div className="flex items-center justify-end h-[62px]">
          <div onClick={addButtonClicked} className="w-[92px] h-[30px] flex items-center justify-center cursor-pointer">
            <span className="text-[12px] font-[600] font-OpenSansRegular text-subTitleColor">+ Customer</span>
          </div>
        </div>
      )}
      <CustomerTable onDeleteCustomer={deleteCustomer} onEditClicked={updateCustomer} customers={customers} />
    </div>
  );
}

export default Customer;
