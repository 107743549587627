import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleError, isAdmin } from "src/Common/function";
import BranchBar from "src/Components/branchbar/BranchBar";
import ReportService from "src/Services/API/report.service";
import UserService from "src/Services/API/user.service";
import HoursWorked from "./hoursworked";
import OpenWorkOrders from "./openworkorders";

const AdHoc = () => {
  const branch = useSelector((state) => state.Branch.branch);
  const [userArray, setUserArray] = useState([]);
  const [openWORecipientIdList, setOpenWORecipientIdList] = useState([]);
  const [hoursWorkedRecipientIdList, setHoursWorkedRecipientIdList] = useState([]);

  useEffect(() => {
    setOpenWORecipientIdList([]);
    setHoursWorkedRecipientIdList([]);
    UserService.getAllUsers(branch)
      .then((response) => {
        if (response.status == 200) {
          setUserArray(response.data);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, [branch]);

  const sendOpenWOReport = (past7Days, past30Days) => {
    ReportService.sendOpenWOReport(branch, openWORecipientIdList, past7Days, past30Days, new Date())
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const sendHoursWorkedReport = (past7Days, past30Days) => {
    ReportService.sendHoursWorkedReport(branch, hoursWorkedRecipientIdList, past7Days, past30Days, new Date())
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <div className="w-full">
      <OpenWorkOrders
        userArray={userArray}
        recipientIdList={openWORecipientIdList}
        setRecipientIdList={setOpenWORecipientIdList}
        sendReport={sendOpenWOReport}
      />
      <div className="h-3" />
      <HoursWorked
        userArray={userArray}
        recipientIdList={hoursWorkedRecipientIdList}
        setRecipientIdList={setHoursWorkedRecipientIdList}
        sendReport={sendHoursWorkedReport}
      />
    </div>
  );
};

export default AdHoc;
