//create a dropdown for day of week selection

import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/Common/common";

export default function DayDropdown({ day, setDay }) {
  const [isDropDownShown, setIsDropDownShown] = useState(false);
  const [days, setDays] = useState([]);

  useEffect(() => {
    const array = [];
    for (let i = 1; i <= 31; i++) {
      array.push(i);
    }
    setDays(array);
  }, []);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropDownShown(false);
      }}
    >
      <div
        onClick={() => setIsDropDownShown(!isDropDownShown)}
        className="w-full px-[10px] py-[3px]  col-span-1 flex items-center justify-between cursor-pointer relative border-[1px] rounded-md"
      >
        <span className="font-OpenSansRegular text-[12px] font-[400]">{day > 0 && day}</span>
        <img src={getImageUrl("/images/common/arrow-down.png")} />

        {isDropDownShown && (
          <div className="w-full absolute h-[140px] px-1 py-1 border-[#E6E7EC] border-[1px] shadow-xl top-[26px] left-0 z-10 bg-white flex flex-col justify-between overflow-auto">
            {days.map((day, index) => {
              return (
                <div
                  key={index}
                  className="w-full h-[18px] flex items-center gap-2 px-2 bg-white hover:bg-gray-200"
                  onClick={() => setDay(day)}
                >
                  <p className="font-OpenSansRegular text-[11px] text-inputEmailTitle font-[400]">{day}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}
