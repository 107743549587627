import { useEffect, useRef, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Viewer from "react-viewer";
import axios from "axios";
import { MdDelete } from "react-icons/md";

import WhiteUploadButton from "src/Components/Common/whiteuploadbutton";
import Button from "src/Components/Button";
import { setPONumber } from "src/Redux/Slices/HeaderSlice";
import WOService from "src/Services/API/wo.service";
import PONumberAddModal from "./Components/ponumberaddmodal";
import PDFPreviewModal from "../WO/Components/pdfpreview";
import ConfirmDialog from "src/Common/confirmdialog";

import {
  formatDate,
  getAvatarUrl,
  getImageUrl,
  getPOReceiptUrl,
  getRealFileName,
  getUploadUrl,
} from "src/Common/common";

// import ApproveRequestButton from "./Components/CreateModal/approverequestbutton";
// import DenyRequestButton from "./Components/CreateModal/denyrequestbutton";
import UserService from "src/Services/API/user.service";
// import AssignTechModal from "./Components/AssignTech/assigntechmodal";
// import ScheduleModal from "./Components/ScheduleModal";
import { paymentItems } from "src/Common/contants";
import {
  downloadImage,
  downloadPdf,
  handleError,
  isManagerOver,
  isClericalOver,
  isAccountant,
} from "src/Common/function";
import ApproveRequestButton from "src/Components/Common/approverequestbutton";
import DenyRequestButton from "src/Components/Common/denyrequestbutton";
import PencilButton from "src/Components/Common/pencilbutton";
import POService from "src/Services/API/po.service";
import CreatePOModal from "../WO/Components/createpomodal/createpomodal";
import POStatusComponent from "./Components/Status/postatuscomponent";
import BlueButton from "src/Components/Common/bluebutton";
import DownloadPdfbutton from "src/Pages/PO/Components/downloadpdfbutton";
import CompleteButton from "./Components/completebutton";

function PODetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [order, setOrder] = useState(location.state.order);
  const [user] = useState(UserService.getCurrentUser());
  const inputFile = useRef(null);
  const [currentUser] = useState(UserService.getCurrentUser());
  const [poNumberModalShown, setPoNumberModalShown] = useState(false);
  const [cratePOModalShown, setCratePOModalShown] = useState(false);
  const [isReceiptDownloading, setIsReceiptDownloading] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  //uploading attachment
  const receiptInputFile = useRef(null);

  //image preview
  const [isImageViewerShown, setIsImageViewerShown] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [previewImageActiveIndex, setPreviewImageActiveIndex] = useState(0);

  //pdf preview
  const [isPDFViewerShown, setIsPDFViewerShown] = useState(false);
  const [previewPDFs, setPreviewPDFs] = useState([]);
  const [previewPDFActiveIndex, setPreviewPDFActiveIndex] = useState(0);

  //attachment delete confirmation
  const [attachmentDeleteModalShown, setAttachmentDeleteModalShown] = useState(false);
  const [attachmentToDeleteIndex, setAttachmentToDeleteIndex] = useState(-1);

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  const getPurchaseOrder = () => {
    POService.getPurchaseOrdersByPoId(order.id)
      .then((response) => {
        if (response.status == 200) {
          setOrder(response.data);
          dispatch(setPONumber(String(response.data.number)));
        }
      })
      .catch((error) => handleError(error));
  };

  const denyRequest = () => {};

  const showPoInputModal = () => {
    // setPoNumberModalShown(true);
    approveRequest();
  };

  const approveRequest = () => {
    POService.update({
      id: order.id,
      data: {
        issuedUser: currentUser.id,
        status: 2,
        issuedDate: new Date(),
      },
    })
      .then((response) => {
        if (response.status == 201) {
          //if this is first po, set the status of wo to "parts"
          if (response.data.wo.pos.length === 1) {
            WOService.update({ id: response.data.wo.id, data: { status: 4 } })
              .then((response) => {
                if (response.status == 201) {
                  console.log("status updated to 'Parts' successfully");
                }
              })
              .catch((error) => {
                handleError(error);
              });
          }
          console.log("approve po reqeuest response.data: ", response.data);
          setOrder(response.data);
          dispatch(setPONumber(response.data.number));
          toast("Purchase order approved");
        }
      })
      .catch((error) => {
        handleError(error);
      });
    // setPoNumberModalShown(false);
  };

  const editPO = () => {
    setCratePOModalShown(true);
  };

  const savePO = async (data) => {
    setCratePOModalShown(false);

    try {
      //upload receipts
      // for (let file of data.data.receipts) {
      //   const fileName = `PO receipt_${String(Math.floor(Date.now()))}.png`;
      //   const fileData = { id: data.id, name: fileName, base64: file.base64 };
      //   console.log(fileData);
      //   await POService.attachFile(fileData);
      // }

      //save data
      let response = await POService.update(data);
      if (response.status == 201) {
        toast("Purchase order updated successfully!");
        setOrder(response.data);
      }
    } catch (error) {
      handleError(error);
    }

    getPurchaseOrder();
  };

  const receiptSelected = (e) => {
    const file = e.target.files[0];
    if (file.size > 167772160) {
      toast("File size cannot exceed more than 20MB");
      return;
    }

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     const base64 = reader.result.split(",")[1];
    //     let newFile = {};
    //     newFile.base64 = base64;
    //     newFile.name = file.name;
    //     newFile.type = file.type;
    //     setReceipts([...receipts, newFile]);
    //   };
    //   reader.readAsDataURL(file);
    // }

    if (file) {
      POService.uploadFile(file, order.id)
        .then((response) => {
          if (response.status == 201) {
            toast("file uploaded successfully");
            setOrder(response.data);
            console.log("🚀 ~ .then ~ response.data:", response.data);
          } else {
            toast("Something went wrong");
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const uploadReceipt = () => {
    receiptInputFile.current.click();
  };

  const deleteAttachment = () => {
    setAttachmentDeleteModalShown(false);
    POService.deleteAttachment(order.id, attachmentToDeleteIndex)
      .then((response) => {
        toast("Attachment deleted successfully");
        getPurchaseOrder();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <div className="w-full px-5 ">
      <Viewer
        visible={isImageViewerShown}
        onClose={() => {
          setIsImageViewerShown(false);
        }}
        images={previewImages}
        activeIndex={previewImageActiveIndex}
      />
      <PONumberAddModal
        shown={poNumberModalShown}
        onSaveClicked={(poNumber) => approveRequest(poNumber)}
        onCancelClicked={() => setPoNumberModalShown(false)}
      />
      <CreatePOModal
        shown={cratePOModalShown}
        onCancelClicked={() => setCratePOModalShown(false)}
        onSubmitClicked={savePO}
        wo={order.wo}
        editingPO={order}
      />

      <PDFPreviewModal
        PDFs={previewPDFs}
        activeIndex={previewPDFActiveIndex}
        shown={isPDFViewerShown}
        onCancel={() => setIsPDFViewerShown(false)}
        isReceipt={true}
      />

      <ConfirmDialog
        title="DELETE ATTACHMENT"
        description="Are you sure you want to delete this attachment?"
        shown={attachmentDeleteModalShown}
        onConfirmClicked={deleteAttachment}
        onCancelClicked={() => setAttachmentDeleteModalShown(false)}
      />

      <div className="w-full h-[56px] flex items-center justify-between">
        <div onClick={() => navigate(-1)} className="w-full h-full flex items-center cursor-pointer">
          <img src={getImageUrl("/images/common/back_arrow.png")} className="w-[24px]" />
          <span className="font-OpenSansRegular text-[14px] font-[700] text-subTitleColor">back</span>
        </div>
        {/* <div
          onClick={async () => {
            try {
              const fileURL = getPOReceiptUrl(`PO_Report_${order.number}.pdf`);
              const response = await axios.head(fileURL);
              if (response.status === 200) {
                downloadPdf(getPOReceiptUrl(`PO_Report_${order.number}.pdf`), `PO_Report_${order.number}.pdf`);
              }
            } catch (error) {
            console.log("🚀 PO receipt doesn't exist:", error);

            POService.generatePOReceipt(order.id)
              .then((response) => {
                downloadPdf(getPOReceiptUrl(`PO_Report_${order.number}.pdf`), `PO_Report_${order.number}.pdf`);
              })
              .catch((error) => {
                console.log("🚀 ~ PODetail ~ error:", error);
              });

            // }
          }}
          className="w-[150px] cursor-pointer px-[20px] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[12px] text-white"
        >
          Download PDF
        </div> */}

        <div className="flex items-center justify-center gap-2">
          <DownloadPdfbutton
            onClicked={() => {
              setIsReceiptDownloading(true);
              POService.generatePOReceipt(order.id)
                .then((response) => {
                  downloadPdf(getPOReceiptUrl(`PO_Report_${order.number}.pdf`), `PO_Report_${order.number}.pdf`);
                })
                .catch((error) => {
                  console.log("🚀 ~ PODetail ~ error:", error);
                })
                .finally(() => {
                  setIsReceiptDownloading(false);
                });
            }}
            isLoading={isReceiptDownloading}
          />
          {order.status !== 4 && (
            <CompleteButton
              onClicked={() => {
                setIsCompleting(true);
                POService.update({
                  id: order.id,
                  data: { status: 4 },
                  eventUser: UserService.getCurrentUser(),
                })
                  .then((response) => {
                    console.log("🚀 ~ updateStatus ~ response:", JSON.stringify(response));
                    if (response.status === 201) {
                      getPurchaseOrder();
                    }
                  })
                  .catch((error) => {
                    handleError(error);
                  })
                  .finally(() => {
                    setIsCompleting(false);
                  });
              }}
              isLoading={isCompleting}
            />
          )}
        </div>
      </div>

      <div className="w-full border-solid border-[1px] border-borderColor mt-3 relative">
        <div className="w-full h-[30px]  flex items-center justify-between px-3">
          <span className="font-OpenSansRegular font-[700] text-[16px] text-titleColor ">Purchase Order Details </span>
          <POStatusComponent value={order.status} />
        </div>
        <div className="w-full h-[50px] border-solid border-t-[1px] border-borderColor flex items-center justify-between px-3">
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-1">
              <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">PO#: </span>
              <span className="font-OpenSansRegular text-[12px] text-titleColor">{order.number}</span>
            </div>
            <div className="flex items-center gap-1">
              <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">WO reference#: </span>
              <span className="font-OpenSansRegular text-[12px] text-titleColor">{order.wo.number}</span>
            </div>
            <div className="flex items-center gap-1">
              <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Date Added: </span>
              <span className="font-OpenSansRegular text-[12px] text-titleColor">{formatDate(order.createdAt)}</span>
            </div>
            <div className="flex items-center gap-1">
              <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Type: </span>
              <span className="font-OpenSansRegular text-[12px] text-titleColor">
                {paymentItems[order.paymentType]}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Customer: </span>
              <span className="font-OpenSansRegular text-[12px] text-titleColor">{order.wo.customer.company}</span>
            </div>
            <div className="flex items-center gap-1">
              {order.issuedUser && order.status >= 2 && (
                <>
                  <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Issued For: </span>
                  <img
                    src={getAvatarUrl(order.issuedUser.avatar)}
                    className="w-[20px] rounded-[10px] cursor-pointer ml-2"
                  />
                  <span className="font-OpenSansRegular text-[12px] text-titleColor">{order.issuedUser.name}</span>
                </>
              )}
              {/* {order.requestedUser && order.status < 2 && (
                <>
                  <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Requested By: </span>
                  <img
                    src={getAvatarUrl(order.requestedUser.avatar)}
                    className="w-[20px] rounded-[10px] cursor-pointer ml-2"
                  />
                  <span className="font-OpenSansRegular text-[12px] text-titleColor">{order.requestedUser.name}</span>
                </>
              )} */}
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-between px-3 ">
          <div className="flex items-center gap-1">
            <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Vendor: </span>
            <span className="font-OpenSansRegular text-[12px] text-titleColor">{order.vendor}</span>
          </div>
        </div>
        <div className="w-full h-[200px] flex items-center justify-between px-3 py-3">
          <div className="w-[50%] h-full flex">
            <div className="flex-1  flex flex-col">
              <div className="w-full h-[50%]">
                <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Description</p>
                <p className="font-OpenSansReg ular text-[12px] text-titleColor">{order.description}</p>
              </div>
              <div className="w-full h-[50%]">
                <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Note</p>
                <p className="font-OpenSansReg ular text-[12px] text-titleColor">{order.note}</p>
              </div>
            </div>
            <div className="w-[350px] flex flex-col">
              <input
                onChange={receiptSelected}
                type="file"
                id="receiptfile"
                ref={receiptInputFile}
                style={{ display: "none" }}
                multiple={false}
                accept="image/*,application/pdf"
              />
              <div className="w-full flex items-center justify-between h-[30px]">
                <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Attachments:</p>
                {order.attachments && (isClericalOver() || isAccountant()) && (
                  <div className="w-[25px]">
                    <Button onClicked={uploadReceipt} title="+" />
                  </div>
                )}
              </div>
              {order.attachments ? (
                <div className="w-full flex-auto overflow-y-auto">
                  {order.attachments.map((file, index) => (
                    <div className="w-full h-[25px] flex items-center gap-3" key={index}>
                      <span
                        onClick={() => {
                          if (file.split(".").pop().toLowerCase() === "pdf") {
                            let temp = [];
                            for (let attachment of order.attachments) {
                              if (attachment.split(".").pop().toLowerCase() === "pdf") {
                                temp.push(attachment);
                                if (attachment === file) {
                                  setPreviewPDFActiveIndex(temp.length - 1);
                                }
                              }
                            }
                            setPreviewPDFs(temp);
                            setIsPDFViewerShown(true);
                          } else {
                            let temp = [];
                            for (let attachment of order.attachments) {
                              if (attachment.split(".").pop().toLowerCase() !== "pdf") {
                                temp.push({ src: getPOReceiptUrl(attachment), alt: "" });
                                if (attachment === file) {
                                  setPreviewImageActiveIndex(temp.length - 1);
                                }
                              }
                            }
                            setPreviewImages(temp);
                            setIsImageViewerShown(true);
                          }
                        }}
                        className="font-OpenSansRegular font-[400] text-[11px] text-titleColor cursor-pointer"
                      >
                        {getRealFileName(file)}
                      </span>
                      <div className="flex items-center gap-2">
                        <div
                          onClick={() => {
                            if (file.split(".").pop().toLowerCase() === "pdf") {
                              downloadPdf(getPOReceiptUrl(file), getRealFileName(file));
                            } else {
                              downloadImage(getPOReceiptUrl(file), getRealFileName(file));
                            }
                          }}
                          className="cursor-pointer"
                        >
                          <FaDownload color="#010D3D" size={13} />
                        </div>
                        {isManagerOver() && (
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setAttachmentDeleteModalShown(true);
                              setAttachmentToDeleteIndex(index);
                            }}
                          >
                            <MdDelete color="#010D3D" size={16} />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <WhiteUploadButton clicked={uploadReceipt} />
              )}
            </div>
          </div>
          <div className="w-[50%] h-full flex items-center justify-around">
            <div className="w-[50%] px-4 h-full">
              {/* <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Technician:</p>
              <div className="w-full h-[25px] flex items-center gap-3 px-3 ">
                <span className="font-OpenSansRegular font-[400] text-[11px] text-titleColor cursor-pointer">
                  {order.issuedUser?.name}
                </span>
              </div> */}
              {/* {techNameArray.map((techName, index) => {
                return (
                  <div className="w-full h-[25px] flex items-center gap-3 px-3 " key={index}>
                    <span className="font-OpenSansRegular font-[400] text-[11px] text-titleColor cursor-pointer">{techName}</span>
                  </div>
                );
              })} */}
            </div>
            <div className="w-[50%] h-[100px] flex items-center justify-end">
              {(order.status == 0 || order.status == 1) && currentUser.roles[0].id <= 2 && (
                <div className="flex flex-col items-center gap-1">
                  <ApproveRequestButton onClicked={showPoInputModal} />
                  <DenyRequestButton onClicked={denyRequest} />
                </div>
              )}
            </div>
          </div>
        </div>

        {(isClericalOver() || isAccountant()) && (
          <div className="absolute right-2 bottom-1 flex items-center gap-2">
            <PencilButton clicked={editPO} />
          </div>
        )}
      </div>

      <div className="w-full border-solid border-[1px] border-borderColor mt-3">
        <div className="w-full h-[30px]  flex items-center justify-between px-3">
          <span className="font-OpenSansRegular font-[700] text-[16px] text-titleColor ">Items </span>
        </div>

        <div className="w-full border-solid border-t-[1px] border-borderColor px-3 py-1">
          {order.poItems.length > 0 ? (
            <>
              {order.poItems.map((item, index) => (
                <div key={index} className="w-full flex items-center justify-between h-[30px]">
                  <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">
                    {item.description}{" "}
                  </span>
                  {item.attachment && (
                    // <a href={getUploadUrl(item.attachment)} target="_blank" download={getRealFileName(item.attachment)}>
                    <div className="h-[25px] flex items-center gap-3 " key={index}>
                      <span
                        className="font-OpenSansRegular font-[400] text-[11px] text-titleColor cursor-pointer"
                        onClick={() => {
                          setPreviewImages([{ src: getUploadUrl(item.attachment), alt: "" }]);
                          setIsImageViewerShown(true);
                        }}
                      >
                        {getRealFileName(item.attachment)}
                      </span>
                      <div
                        onClick={() => downloadImage(getUploadUrl(item.attachment), getRealFileName(item.attachment))}
                        className="cursor-pointer"
                      >
                        <FaDownload color="#010D3D" size={13} />
                      </div>
                    </div>
                    // </a>
                  )}
                </div>
              ))}
            </>
          ) : (
            <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">No items </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default PODetail;
