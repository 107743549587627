import { current } from "@reduxjs/toolkit";
import { isAdmin } from "src/Common/function";
import api from "./api";
import UserService from "./user.service";

//This function is for admin
const sendOpenWOReport = (branch, recipients, past7Days, past30Days, date) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.post("/api/wo/send_open_wo_report", { branchId, recipients, past7Days, past30Days, date });
};

const sendHoursWorkedReport = (branch, recipients, past7Days, past30Days, date) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.post("/api/report/send_hours_worked_report", { branchId, recipients, past7Days, past30Days, date });
};

const create = (data) => {
  return api.post("/api/report/create", data);
};

const getAll = (branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.get("/api/report/get_all", { params: { branchId } });
};

const deleteReport = (id) => {
  return api.get("/api/report/delete", { params: { id } });
};

const update = (id, data) => {
  return api.post("/api/report/update", { id, data });
};

const ReportService = {
  sendOpenWOReport,
  sendHoursWorkedReport,
  create,
  getAll,
  deleteReport,
  update,
};

export default ReportService;
