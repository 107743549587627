import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { formatDate } from "src/Common/common";
import ConfirmDialog from "src/Common/confirmdialog";
import BranchService from "src/Services/API/branch.service";
import RoleService from "src/Services/API/role.service";
import UserService from "src/Services/API/user.service";

function Archive({ branch }) {
  const navigate = useNavigate();
  const [roleArray, setRoleArray] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [branchArray, setBranchArray] = useState([]);
  const [index, setIndex] = useState(0);
  const [isArchiveConfirmDialogShown, setIsArchiveConfirmDialogShown] = useState(false);
  const [roleChangedUserArray, setRoleChangedUserArray] = useState([]);

  useEffect(() => {
    BranchService.getAll()
      .then((response) => {
        console.log("🚀 ~ BranchService.getAll ~ response:", response.data);
        setBranchArray(response.data);
      })
      .catch((error) => {
        toast("An error occured");
      });
  }, []);

  useEffect(() => {
    RoleService.getAll()
      .then((response) => {
        console.log("Role list: ", response);
        if (response.status == 200) {
          setRoleArray(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
    getAllUsers();
  }, [branch]);

  const getAllUsers = () => {
    UserService.getAll(branch)
      .then((response) => {
        console.log("User list: ", response);
        if (response.status == 200) {
          let users = response.data.filter((user) => user.isEnabled === false);
          for (let i = 0; i < users.length; i++) {
            const date = new Date(users[i].createdAt);
            users[i].formattedCreatedAt = formatDate(date);
          }
          setUserArray(users);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
  };

  // const deleteUser = () => {
  //   UserService.deleteUser(userArray[index].id)
  //     .then((response) => {
  //       if (response.status == 201) {
  //         toast("User archived successfully");
  //         getAllUsers();
  //       }
  //       setIsDeleteConfirmDialogShown(false);
  //     })
  //     .catch((error) => {
  //       setIsDeleteConfirmDialogShown(false);

  //       if (error.response) {
  //         toast(error.response.data.message);
  //       } else {
  //         toast("An error occured");
  //       }
  //     });
  // };

  const restore = () => {
    UserService.restoreUser(userArray[index].id)
      .then((response) => {
        if (response.status == 201) {
          toast("User archived successfully");
          getAllUsers();
        }
        setIsArchiveConfirmDialogShown(false);
      })
      .catch((error) => {
        setIsArchiveConfirmDialogShown(false);

        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });
  };

  // const roleChange = (user, newRole) => {
  //   user.roles = [newRole];
  //   console.log("🚀 ~ roleChange ~ user:", user, newRole);

  //   UserService.updateUser(user)
  //     .then((response) => {
  //       if (response.status == 201) {
  //         toast("User role changed successfully");
  //       } else {
  //         toast("Something went wrong!");
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         toast(error.response.data.message);
  //       } else {
  //         toast("An error occured");
  //       }
  //     });
  // };

  // const branchChange = (user, branch, checked) => {
  //   let userData;
  //   if (checked) {
  //     userData = { ...user, branches: [...user.branches, branch] };
  //   } else {
  //     const branchArray = user.branches.filter((item) => item.id !== branch.id);
  //     userData = { ...user, branches: branchArray };
  //   }

  //   UserService.updateUser(userData)
  //     .then((response) => {
  //       if (response.status == 201) {
  //         getAllUsers();
  //       } else {
  //         toast("Something went wrong!");
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         toast(error.response.data.message);
  //       } else {
  //         toast("An error occured");
  //       }
  //     });
  // };

  return (
    <div>
      <ConfirmDialog
        title="RESTORE USER"
        description="Are you sure you want to restore this user?"
        shown={isArchiveConfirmDialogShown}
        onConfirmClicked={restore}
        onCancelClicked={() => setIsArchiveConfirmDialogShown(false)}
      />

      <table className="w-full mt-3">
        <thead>
          <tr className="border-t-[1px] border-b-[1px] border-borderColor">
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-4 h-[40px] text-titleColor">
                <span>Name</span>
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-4 h-[40px] text-titleColor">
                <span>Email</span>
              </div>
            </th>
            {/* <th className="border-r-[1px] border-borderColor w-[200px]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Role
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor w-[250px]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Branch
              </div>
            </th> */}
            <th className="w-[100px]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-2 h-[40px] text-titleColor"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {userArray.map((user, i) => (
            <tr key={i} className="border-b-[1px] border-borderColor h-[40px]">
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {user.name}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {user.email}
                </div>
              </td>

              {/* <td className="border-r-[1px] border-borderColor relative">
                <RoleDropDown user={user} roles={roleArray} roleClicked={roleChange} />
              </td>
              <td className="border-r-[1px] border-borderColor relative">
                <BranchDropDown user={user} branches={branchArray} onChange={branchChange} />
              </td> */}

              <td>
                <div className="flex items-center justify-center">
                  <div
                    onClick={() => {
                      setIndex(i);
                      setIsArchiveConfirmDialogShown(true);
                    }}
                    className="w-[70px] h-[25px] text-[12px] rounded-[6px] border-[1px] border-subTitleColor flex items-center justify-center text-subTitleColor cursor-pointer "
                  >
                    RESTORE
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Archive;
