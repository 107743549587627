import { useState } from "react";
import { formatDate } from "src/Common/common";
import ConfirmDialog from "src/Common/confirmdialog";
import DropDown from "src/Components/EditDeleteDropdown/dropdown";

function BranchTable({ branches, onDeleteBranch, onEditClicked }) {
  const [isDeleteConfirmDialogShown, setIsDeleteConfirmDialogShown] = useState(false);
  const [indexForDeletion, setIndexForDeletion] = useState(0);

  const deleteClicked = (index) => {
    setIsDeleteConfirmDialogShown(true);
    setIndexForDeletion(index);
  };

  const editClicked = (index) => {
    onEditClicked(index);
  };

  return (
    <div>
      {isDeleteConfirmDialogShown && (
        <ConfirmDialog
          title="DELETE BRANCH"
          description="Are you sure you want to delete this?"
          shown={isDeleteConfirmDialogShown}
          onConfirmClicked={() => {
            onDeleteBranch(indexForDeletion);
            setIsDeleteConfirmDialogShown(false);
          }}
          onCancelClicked={() => setIsDeleteConfirmDialogShown(false)}
        />
      )}

      <table className="w-full">
        <thead>
          <tr className="border-b-[1px] border-borderColor">
            <th className="w-[15%] border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-4 h-[40px] text-titleColor">
                <span>Name</span>
              </div>
            </th>
            <th className="w-[15%] border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                PO Start Number
              </div>
            </th>

            <th className="w-[40%] border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Address
              </div>
            </th>

            <th className="w-[15%] border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Phone
              </div>
            </th>

            <th className="branchTableActionHeaderWidth">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Timezone
              </div>
            </th>
            <th className="w-[30px]"></th>
          </tr>
        </thead>
        <tbody>
          {branches.map((branch, i) => (
            <tr key={i} className="border-b-[1px] border-borderColor h-[40px]">
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {branch.name}
                </div>
              </td>

              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {branch.poStartNumber}
                </div>
              </td>

              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {branch.address}
                </div>
              </td>

              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {branch.phone}
                </div>
              </td>

              <td>
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {branch.timezone}
                </div>
              </td>
              <td>
                <DropDown index={i} onDeleteClicked={deleteClicked} onEditClicked={editClicked} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BranchTable;
