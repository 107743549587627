function BlueServiceTicketButton({ clicked }) {
  return (
    <div
      onClick={clicked}
      className="w-[120px] h-[30px] rounded-[6px] border-solid border-[1px] bg-blueColor flex items-center justify-center cursor-pointer"
    >
      <div className="flex gap-1">
        <p className="font-OpenSansRegular font-[600] text-[10px] text-white">SERVICE TICKET</p>
      </div>
    </div>
  );
}

export default BlueServiceTicketButton;
