import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  woNumber: "",
  poNumber: "",
  customerName: "",
};

export const HeaderSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setWONumber: (state, action) => {
      state.woNumber = action.payload;
    },
    setPONumber: (state, action) => {
      state.poNumber = action.payload;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload;
    },
  },
});

export const { setWONumber, setPONumber, setCustomerName } = HeaderSlice.actions;

export default HeaderSlice.reducer;
