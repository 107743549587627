import { current } from "@reduxjs/toolkit";
import api from "./api";
import UserService from "./user.service";
import { useSelector } from "react-redux";
import { isAccountant, isAdmin } from "src/Common/function";

const getAll = (branch, keyword) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin() && !isAccountant()) {
    branchId = currentUser.branches[0].id;
  }
  console.log("🚀 ~ getAll ~ branchId:", currentUser, branch, branchId);
  return api.get("/api/customer/get_all", { params: { branchId, keyword } });
};

const saveCustomer = (customer, branch) => {
  console.log("🚀 ~ saveCustomer ~ customer:", customer, branch);
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin() && !isAccountant()) {
    branchId = currentUser.branches[0].id;
  }
  customer["branch"] = branchId;
  return api.post("/api/customer/add_customer", customer);
};

const deleteCustomer = (id) => {
  return api.post("/api/customer/delete_customer", { customerId: id });
};

const updateCustomer = (customer) => {
  return api.post("/api/customer/update_customer", { ...customer });
};

const getOne = (customerId) => {
  return api.get("/api/customer/get_one", { params: { customerId } });
};

const CustomerService = {
  getOne,
  getAll,
  saveCustomer,
  deleteCustomer,
  updateCustomer,
};

export default CustomerService;
