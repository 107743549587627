import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/Common/common";

function StatusDropdown({ statusSelected, statusList, cycle }) {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const statusClicked = (index) => {
    statusSelected(index);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropDownOpen(false);
      }}
    >
      <div
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
        className="w-full h-[30px] px-[15px] py-[5px] border-[1px] rounded-md col-span-1 flex items-center justify-between cursor-pointer relative"
      >
        <span className="font-OpenSansRegular text-[11px] font-[400]">{statusList[cycle]}</span>
        <img src={getImageUrl("/images/common/arrow-down.png")} />

        {isDropDownOpen && (
          <div className="w-full absolute px-1 py-1 border-[#E6E7EC] border-[1px] top-[32px] left-0 z-10 bg-white flex flex-col justify-between shadow-xl">
            {statusList.map((status, index) => {
              return (
                <div
                  key={index}
                  className="w-full h-[30px] flex items-center gap-2 px-2 relative"
                  onClick={() => statusClicked(index)}
                >
                  <p className="font-OpenSansRegular text-[11px] text-inputEmailTitle font-[400]">{status}</p>
                  {index < statusList.length - 1 && (
                    <div className="w-full h-[1px] bg-gray-200 absolute left-0 right-0 bottom-0" />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}

export default StatusDropdown;
