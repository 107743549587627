import { Oval } from "react-loader-spinner";

function DownloadPdfbutton({ onClicked, isLoading = false }) {
  return (
    <>
      {isLoading ? (
        <div className="w-[120px] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center">
          <Oval
            visible={true}
            height="16"
            width="16"
            color="#FFFFFF"
            strokeWidth={8}
            secondaryColor="#FFEEFF"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div
          onClick={onClicked}
          className="cursor-pointer w-[120px] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[12px] text-white"
        >
          <span>Download PDF</span>
        </div>
      )}
    </>
  );
}

export default DownloadPdfbutton;
