import { current } from "@reduxjs/toolkit";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import StatusDropdown from "./components/statusdropdown";
import UserSelectModal from "./components/userselectmodal";
import UserService from "src/Services/API/user.service";
import { handleError, getUserNameArray, isAdmin } from "src/Common/function";
import BranchBar from "src/Components/branchbar/BranchBar";
import NotificationService from "src/Services/API/notification.service";
import Receivers from "./components/receivers";

const email_woStatusList = [
  "Issued",
  "Service Ticket Reviewed",
  "Updated",
  "Service Ticket Completed",
  "Billed",
  "Incomplete Service Ticket",
];
const email_poStatusList = ["Issued", "Completed", "Updated", "Requested", "Reimbursed PO Completed"];

const web_woStatusList = [
  "Technician accepts WO assignment",
  "Service Ticket Completed",
  "Tech is 'Enroute'",
  "Tech is 'Arrived'",
];
const web_poStatusList = ["PO is requested from technician", "PO receipt has been submitted"];

const mobile_woStatusList = ["requested", "issued"];
const mobile_poStatusList = ["issued to a technician"];

const sms_woStatusList = ["Requested", "Issued", "Service Ticket Completed", "Service Ticket Reviewed", "Billed"];
const sms_poStatusList = ["Requested", "Issued", "Completed"];

const NotificationCenter = () => {
  const branch = useSelector((state) => state.Branch.branch);
  const [type, setType] = useState(0);
  const [flow, setFlow] = useState(0);
  const [emailWoStatus, setEmailWoStatus] = useState(0);
  const [emailPoStatus, setEmailPoStatus] = useState(0);
  const [webWoStatus, setWebWoStatus] = useState(0);
  const [webPoStatus, setWebPoStatus] = useState(0);
  const [mobileWoStatus, setMobileWoStatus] = useState(0);
  const [mobilePoStatus, setMobilePoStatus] = useState(0);
  const [smsWoStatus, setSmsWoStatus] = useState(0);
  const [smsPoStatus, setSmsPoStatus] = useState(0);
  const [userArray, setUserArray] = useState([]);

  //Email
  const [emailWoRecipientIdList, setEmailWoRecipientIdList] = useState([]);
  const [emailPoRecipientIdList, setEmailPoRecipientIdList] = useState([]);
  const [emailWoRecipientNameList, setEmailWoRecipientNameList] = useState([]);
  const [emailPoRecipientNameList, setEmailPoRecipientNameList] = useState([]);

  //Web
  const [webWoRecipientIdList, setWebWoRecipientIdList] = useState([]);
  const [webPoRecipientIdList, setWebPoRecipientIdList] = useState([]);
  const [webWoRecipientNameList, setWebWoRecipientNameList] = useState([]);
  const [webPoRecipientNameList, setWebPoRecipientNameList] = useState([]);

  //Mobile
  const [mobileWoRecipientIdList, setMobileWoRecipientIdList] = useState([]);
  const [mobilePoRecipientIdList, setMobilePoRecipientIdList] = useState([]);
  const [mobileWoRecipientNameList, setMobileWoRecipientNameList] = useState([]);
  const [mobilePoRecipientNameList, setMobilePoRecipientNameList] = useState([]);

  //SMS
  const [smsWoRecipientIdList, setSmsWoRecipientIdList] = useState([]);
  const [smsPoRecipientIdList, setSmsPoRecipientIdList] = useState([]);
  const [smsWoRecipientNameList, setSmsWoRecipientNameList] = useState([]);
  const [smsPoRecipientNameList, setSmsPoRecipientNameList] = useState([]);

  const [userSelectModalShown, setUserSelectModalShown] = useState(false);

  useEffect(() => {
    //Email
    setEmailWoRecipientIdList([]);
    setEmailPoRecipientIdList([]);
    setEmailWoRecipientNameList([]);
    setEmailPoRecipientNameList([]);
    //Web
    setWebWoRecipientIdList([]);
    setWebPoRecipientIdList([]);
    setWebWoRecipientNameList([]);
    setWebPoRecipientNameList([]);
    //Mobile
    setMobileWoRecipientIdList([]);
    setMobilePoRecipientIdList([]);
    setMobileWoRecipientNameList([]);
    setMobilePoRecipientNameList([]);

    UserService.getAll(branch)
      .then((response) => {
        if (response.status == 200) {
          setUserArray(response.data);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, [branch]);

  useEffect(() => {
    if (userArray.length === 0) return;
    NotificationService.get(0, 0, emailWoStatus, branch)
      .then((response) => {
        if (response.status == 201) {
          const userIdArray = response.data.recipientList.split(",");
          console.log("userIdArray: ", userIdArray);
          setEmailWoRecipientIdList(userIdArray);
          setEmailWoRecipientNameList(getUserNameArray(userIdArray, userArray));
        }
      })
      .catch((error) => {
        setEmailWoRecipientIdList([]);
        setEmailWoRecipientNameList([]);
      });
  }, [emailWoStatus, userArray]);

  useEffect(() => {
    if (userArray.length === 0) return;
    NotificationService.get(0, 1, emailPoStatus, branch)
      .then((response) => {
        if (response.status == 201) {
          const userIdArray = response.data.recipientList.split(",");
          console.log("userIdArray: ", userIdArray);
          setEmailPoRecipientIdList(userIdArray);
          setEmailPoRecipientNameList(getUserNameArray(userIdArray, userArray));
        }
      })
      .catch((error) => {
        setEmailPoRecipientIdList([]);
        setEmailPoRecipientNameList([]);
      });
  }, [emailPoStatus, userArray]);

  useEffect(() => {
    if (userArray.length === 0) return;
    NotificationService.get(1, 0, webWoStatus, branch)
      .then((response) => {
        if (response.status == 201) {
          const userIdArray = response.data.recipientList.split(",");
          setWebWoRecipientIdList(userIdArray);
          setWebWoRecipientNameList(getUserNameArray(userIdArray, userArray));
        }
      })
      .catch((error) => {
        setWebWoRecipientIdList([]);
        setWebWoRecipientNameList([]);
      });
  }, [webWoStatus, userArray]);

  useEffect(() => {
    if (userArray.length === 0) return;
    NotificationService.get(1, 1, webPoStatus, branch)
      .then((response) => {
        if (response.status == 201) {
          const userIdArray = response.data.recipientList.split(",");
          setWebPoRecipientIdList(userIdArray);
          setWebPoRecipientNameList(getUserNameArray(userIdArray, userArray));
        }
      })
      .catch((error) => {
        setWebPoRecipientIdList([]);
        setWebPoRecipientNameList([]);
      });
  }, [webPoStatus, userArray]);

  useEffect(() => {
    if (userArray.length === 0) return;
    NotificationService.get(2, 0, mobileWoStatus, branch)
      .then((response) => {
        if (response.status == 201) {
          const userIdArray = response.data.recipientList.split(",");
          setMobileWoRecipientIdList(userIdArray);
          setMobileWoRecipientNameList(getUserNameArray(userIdArray, userArray));
        }
      })
      .catch((error) => {
        setMobileWoRecipientIdList([]);
        setMobileWoRecipientNameList([]);
      });
  }, [mobileWoStatus, userArray]);

  useEffect(() => {
    if (userArray.length === 0) return;
    NotificationService.get(2, 1, mobilePoStatus, branch)
      .then((response) => {
        if (response.status == 201) {
          const userIdArray = response.data.recipientList.split(",");
          setMobilePoRecipientIdList(userIdArray);
          setMobilePoRecipientNameList(getUserNameArray(userIdArray, userArray));
        }
      })
      .catch((error) => {
        setMobilePoRecipientIdList([]);
        setMobilePoRecipientNameList([]);
      });
  }, [mobilePoStatus, userArray]);

  useEffect(() => {
    if (userArray.length === 0) return;
    NotificationService.get(3, 0, smsWoStatus, branch)
      .then((response) => {
        if (response.status == 201) {
          const userIdArray = response.data.recipientList.split(",");
          setSmsWoRecipientIdList(userIdArray);
          setSmsWoRecipientNameList(getUserNameArray(userIdArray, userArray));
        }
      })
      .catch((error) => {
        setSmsWoRecipientIdList([]);
        setSmsWoRecipientNameList([]);
      });
  }, [smsWoStatus, userArray]);

  useEffect(() => {
    if (userArray.length === 0) return;
    NotificationService.get(3, 1, smsPoStatus, branch)
      .then((response) => {
        if (response.status == 201) {
          const userIdArray = response.data.recipientList.split(",");
          setSmsPoRecipientIdList(userIdArray);
          setSmsPoRecipientNameList(getUserNameArray(userIdArray, userArray));
        }
      })
      .catch((error) => {
        setSmsPoRecipientIdList([]);
        setSmsPoRecipientNameList([]);
      });
  }, [smsPoStatus, userArray]);

  const saveNotification = (userArray) => {
    let temp = [];
    for (let user of userArray) {
      temp.push(user.id);
    }

    let event;
    switch (type) {
      case 0:
        event = flow === 0 ? emailWoStatus : emailPoStatus;
        break;
      case 1:
        event = flow === 0 ? webWoStatus : webPoStatus;
        break;
      case 2:
        event = flow === 0 ? mobileWoStatus : mobilePoStatus;
        break;
      case 3:
        event = flow === 0 ? smsWoStatus : smsPoStatus;
        break;
    }
    let recipientList = temp.length > 0 ? temp.join() : null;
    console.log("TTTTTT: ", type, flow, event, recipientList);
    NotificationService.create(type, flow, event, recipientList, branch)
      .then((response) => {
        console.log("response: ", response);
        if (response.status == 200 || response.status == 201) {
          const userIdArray = response.data.recipientList ? response.data.recipientList.split(",") : [];

          switch (type) {
            case 0:
              if (flow === 0) {
                setEmailWoRecipientIdList(userIdArray);
                setEmailWoRecipientNameList(getUserNameArray(userIdArray, userArray));
              } else {
                setEmailPoRecipientIdList(userIdArray);
                setEmailPoRecipientNameList(getUserNameArray(userIdArray, userArray));
              }
              break;
            case 1:
              if (flow === 0) {
                setWebWoRecipientIdList(userIdArray);
                setWebWoRecipientNameList(getUserNameArray(userIdArray, userArray));
              } else {
                setWebPoRecipientIdList(userIdArray);
                setWebPoRecipientNameList(getUserNameArray(userIdArray, userArray));
              }
              break;
            case 2:
              if (flow === 0) {
                setMobileWoRecipientIdList(userIdArray);
                setMobileWoRecipientNameList(getUserNameArray(userIdArray, userArray));
              } else {
                setMobilePoRecipientIdList(userIdArray);
                setMobilePoRecipientNameList(getUserNameArray(userIdArray, userArray));
              }
              break;
            case 3:
              if (flow === 0) {
                setSmsWoRecipientIdList(userIdArray);
                setSmsWoRecipientNameList(getUserNameArray(userIdArray, userArray));
              } else {
                setSmsPoRecipientIdList(userIdArray);
                setSmsPoRecipientNameList(getUserNameArray(userIdArray, userArray));
              }
              break;
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });

    setUserSelectModalShown(false);
  };

  const getSelectedUserIdArray = () => {
    switch (type) {
      case 0:
        return flow === 0 ? emailWoRecipientIdList : emailPoRecipientIdList;
      case 1:
        return flow === 0 ? webWoRecipientIdList : webPoRecipientIdList;
      case 2:
        return flow === 0 ? mobileWoRecipientIdList : mobilePoRecipientIdList;
      case 3:
        return flow === 0 ? mobileWoRecipientIdList : mobilePoRecipientIdList;
      default:
        return "";
    }
  };

  return (
    <div className="w-full">
      <UserSelectModal
        shown={userSelectModalShown}
        userArray={userArray}
        selectedUserIdArray={getSelectedUserIdArray()}
        onCancel={() => setUserSelectModalShown(false)}
        onSave={(userArray) => saveNotification(userArray)}
      />
      <div className="px-7 py-2">{isAdmin() && <BranchBar allEnabled={false} />}</div>
      <p className="font-OpenSansRegular text-[14px] text-black mt-3">
        Create notifications for your customized statuses.
      </p>
      <p className="font-OpenSansBold text-[15px] text-black mt-2">Email Notification</p>
      <div className="flex items-center justify-between w-full mt-3 pl-5 gap-3">
        <div className="w-[200px] flex flex-col gap-3">
          <p className="font-OpenSansMedium text-[13px] text-black">Flow</p>
          <div className="font-OpenSansBold text-[13px] text-black h-[30px] flex items-center">Work Order</div>
          <div className="font-OpenSansBold text-[13px] text-black h-[30px] flex items-center">Purchase Order</div>
        </div>

        <div className="w-[300px] flex flex-col gap-3">
          <p className="font-OpenSansMedium text-[13px] text-black ">Status</p>
          <StatusDropdown statusList={email_woStatusList} statusSelected={(index) => setEmailWoStatus(index)} />
          <StatusDropdown statusList={email_poStatusList} statusSelected={(index) => setEmailPoStatus(index)} />
        </div>

        <div className="flex-1 flex flex-col gap-3">
          <Receivers
            woRecipients={emailWoRecipientNameList}
            poRecipients={emailPoRecipientNameList}
            setFlow={(value) => setFlow(value)}
            setUserSelectModalShown={(value) => {
              setUserSelectModalShown(value);
              setType(0);
            }}
          />
        </div>
      </div>

      <p className="font-OpenSansBold text-[15px] text-black mt-5">Web APP Notification</p>
      <div className="flex items-center justify-between w-full mt-3 pl-5 gap-3">
        <div className="w-[200px] flex flex-col gap-3">
          <p className="font-OpenSansMedium text-[13px] text-black">Flow</p>
          <div className="font-OpenSansBold text-[13px] text-black h-[30px] flex items-center">Work Order</div>
          <div className="font-OpenSansBold text-[13px] text-black h-[30px] flex items-center">Purchase Order</div>
        </div>

        <div className="w-[300px] flex flex-col gap-3">
          <p className="font-OpenSansMedium text-[13px] text-black ">Status</p>
          <StatusDropdown statusList={web_woStatusList} statusSelected={(index) => setWebWoStatus(index)} />
          <StatusDropdown statusList={web_poStatusList} statusSelected={(index) => setWebPoStatus(index)} />
        </div>

        <div className="flex-1 flex flex-col gap-3">
          <Receivers
            woRecipients={webWoRecipientNameList}
            poRecipients={webPoRecipientNameList}
            setFlow={(value) => setFlow(value)}
            setUserSelectModalShown={(value) => {
              setUserSelectModalShown(value);
              setType(1);
            }}
          />
        </div>
      </div>

      <p className="font-OpenSansBold text-[15px] text-black mt-5">Mobile APP Notification</p>
      <div className="flex items-center justify-between w-full mt-3 pl-5 gap-3">
        <div className="w-[200px] flex flex-col gap-3">
          <p className="font-OpenSansMedium text-[13px] text-black">Flow</p>
          <div className="font-OpenSansBold text-[13px] text-black h-[30px] flex items-center">Work Order</div>
          <div className="font-OpenSansBold text-[13px] text-black h-[30px] flex items-center">Purchase Order</div>
        </div>

        <div className="w-[300px] flex flex-col gap-3">
          <p className="font-OpenSansMedium text-[13px] text-black">Status</p>
          <StatusDropdown statusList={mobile_woStatusList} statusSelected={(index) => setMobileWoStatus(index)} />
          <StatusDropdown statusList={mobile_poStatusList} statusSelected={(index) => setMobilePoStatus(index)} />
        </div>

        <div className="flex-1 flex flex-col gap-3">
          <Receivers
            woRecipients={mobileWoRecipientNameList}
            poRecipients={mobilePoRecipientNameList}
            setFlow={(value) => setFlow(value)}
            setUserSelectModalShown={(value) => {
              setUserSelectModalShown(value);
              setType(2);
            }}
          />
        </div>
      </div>

      <p className="font-OpenSansBold text-[15px] text-black mt-5">SMS Notification</p>
      <div className="flex items-center justify-between w-full mt-3 pl-5 gap-3">
        <div className="w-[200px] flex flex-col gap-3">
          <p className="font-OpenSansMedium text-[13px] text-black">Flow</p>
          <div className="font-OpenSansBold text-[13px] text-black h-[30px] flex items-center">Work Order</div>
          <div className="font-OpenSansBold text-[13px] text-black h-[30px] flex items-center">Purchase Order</div>
        </div>

        <div className="w-[300px] flex flex-col gap-3">
          <p className="font-OpenSansMedium text-[13px] text-black">Status</p>
          <StatusDropdown statusList={sms_woStatusList} statusSelected={(index) => setSmsWoStatus(index)} />
          <StatusDropdown statusList={sms_poStatusList} statusSelected={(index) => setSmsPoStatus(index)} />
        </div>

        <div className="flex-1 flex flex-col gap-3">
          <Receivers
            woRecipients={smsWoRecipientNameList}
            poRecipients={smsPoRecipientNameList}
            setFlow={(value) => setFlow(value)}
            setUserSelectModalShown={(value) => {
              setUserSelectModalShown(value);
              setType(3);
            }}
          />
        </div>
      </div>

      {/* <p className="font-OpenSansMedium text-[13px] text-black w-[30%]">Receivers</p>
          <div
            onClick={() => {
              setUserSelectModalShown(true);
              setFlow(0);
            }}
            className="h-[30px] border-[1px] rounded-md border-gray-200 cursor-pointer flex items-center px-2"
          >
            <p className="font-OpenSansMedium text-[13px] text-black">{limitString(woRecipientNameList, 40)}</p>
          </div>
          <div
            onClick={() => {
              setUserSelectModalShown(true);
              setFlow(1);
            }}
            className="h-[30px] border-[1px] rounded-md border-gray-200 cursor-pointer flex items-center px-2"
          >
            <p className="font-OpenSansMedium text-[13px] text-black">{limitString(poRecipientNameList, 40)}</p>
          </div> */}
    </div>
  );
};

export default NotificationCenter;
