import { useEffect, useState } from "react";
import { getUserNameArray } from "src/Common/function";
import BlueButton from "src/Components/Common/bluebutton";
import UserSelectModal from "../../Notification/components/userselectmodal";
import Recipients from "../components/recipients";

const HoursWorked = ({ userArray, recipientIdList, setRecipientIdList, sendReport }) => {
  const [userSelectModalShown, setUserSelectModalShown] = useState(false);
  const [technicianNameList, setTechnicianNameList] = useState([]);

  const [past7Days, setPast7Days] = useState(false);
  const [past30Days, setPast30Days] = useState(false);

  useEffect(() => {
    if (recipientIdList.length === 0) {
      setTechnicianNameList("");
    }
  }, [recipientIdList]);

  const saveReport = (users) => {
    let recipientList = [];
    for (let user of users) {
      recipientList.push(String(user.id));
    }
    setRecipientIdList(recipientList);
    setTechnicianNameList(getUserNameArray(recipientList, userArray));
    setUserSelectModalShown(false);
  };

  return (
    <div className="w-full">
      <UserSelectModal
        shown={userSelectModalShown}
        userArray={userArray}
        selectedUserIdArray={recipientIdList}
        onCancel={() => setUserSelectModalShown(false)}
        onSave={(userArray) => saveReport(userArray)}
      />

      <div className="border-gray-100 border-[1px] rounded-md flex flex-col items-center justify-between w-[500px] gap-3 p-3 relative">
        <div className="w-full flex flex-col gap-3">
          <div className="font-OpenSansBold text-[16px] text-black h-[30px] flex items-center">Hours Worked</div>
        </div>

        <div className="w-full flex flex-col gap-3">
          <Recipients
            recipients={technicianNameList}
            onClick={() => {
              setUserSelectModalShown(true);
            }}
          />
        </div>
        <div className="w-full flex items-center gap-2 h-[26px]">
          <input
            type="checkbox"
            value={past7Days}
            onChange={(event) => {
              setPast7Days(event.target.checked);
            }}
          />
          <p className="font-OpenSansMedium text-[13px] text-black w-[80px]">Past 7 days</p>
        </div>

        <div className="w-full flex items-center gap-2 h-[26px]">
          <input type="checkbox" value={past30Days} onChange={(event) => setPast30Days(event.target.checked)} />
          <p className="font-OpenSansMedium text-[13px] text-black w-[80px] ">Past 30 days</p>
        </div>
        <div className="absolute right-3 bottom-5">
          <BlueButton title="Send Report Now" onClicked={() => sendReport(past7Days, past30Days)} />
        </div>
      </div>
    </div>
  );
};

export default HoursWorked;
