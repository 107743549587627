import React from "react";
import ServiceCallType from "./servicecalltype";
import QuotedType from "./quotedtype";
import PMType from "./pmtype";
import PartsOnlyType from "./partsonly";

const WOType = ({ type }) => {
  return type === 0 ? <ServiceCallType /> : type === 1 ? <QuotedType /> : type === 2 ? <PMType /> : <PartsOnlyType />;
};

export default WOType;
