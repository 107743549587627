import { getImageUrl } from "src/Common/common";
import { Oval } from "react-loader-spinner";

function DownloadButton({ isLoading, onClicked, title = "Download" }) {
  return (
    <div
      onClick={() => !isLoading && onClicked()}
      className="w-[100px] border-borderColor rounded-[4px] border-[1px] flex items-center justify-center px-[10px] h-[24px] cursor-pointer"
    >
      {isLoading ? (
        <Oval
          visible={true}
          height="16"
          width="16"
          color="#0065FD"
          strokeWidth={8}
          secondaryColor="#0065EE"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        <div className="flex items-center gap-2">
          <img src={getImageUrl("/images/common/download_black.png")} className="w-[10px]" />
          <span className="text-[#67737E] font-OpenSansRegular text-[12px] font-[600]">{title}</span>
        </div>
      )}
    </div>
  );
}

export default DownloadButton;
