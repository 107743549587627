import moment from "moment";
import { API_URL } from "src/Common/function";
import UserService from "src/Services/API/user.service";

export const getAvatarUrl = (imageName) => {
  const currentUser = UserService.getCurrentUser();
  return `${API_URL}/${currentUser.business}/avatars/${imageName}`;
};

export const getUploadUrl = (fileName) => {
  const currentUser = UserService.getCurrentUser();
  return `${API_URL}/${currentUser.business}/uploads/${encodeURIComponent(fileName)}`;
  // return `${API_URL}/${currentUser.business}/uploads/${fileName.replace(/#/g, "%23").replace(/ /g, "%20")}`;
};

export const getPOReceiptUrl = (fileName) => {
  const currentUser = UserService.getCurrentUser();
  return `${API_URL}/${currentUser.business}/poreceipt/${encodeURIComponent(fileName)}`;
  // return `${API_URL}/${currentUser.business}/poreceipt/${fileName.replace(/#/g, "%23").replace(/ /g, "%20")}`;
};

export const getLogoUrl = () => {
  const currentUser = UserService.getCurrentUser();
  return `${API_URL}/${currentUser.business}/company/logo.png?${Date.now()}`;
};

export const getRealFileName = (fileName) => {
  if (fileName) {
    const lastDotIndex = fileName.lastIndexOf(".");
    const fileNameWithoutExtension = fileName.substring(0, lastDotIndex);
    const fileNameExtension = fileName.substring(lastDotIndex + 1);

    const lastHyphenIndex = fileNameWithoutExtension.lastIndexOf("-");
    const newFileName = fileNameWithoutExtension.substring(0, lastHyphenIndex);

    return newFileName + "." + fileNameExtension;
  }
};

// export const getPOItemAttachmentRealFileName = (fileName) => {
//   if (fileName) {
//     let fileNameParts = fileName.split(".");
//     return fileName.split("---")[0] + "." + fileNameParts.pop();
//   }
// };

export const getImageUrl = (imagePath) => {
  return process.env.PUBLIC_URL + imagePath;
};

export const formatDate = (dateString, formatString = "MM/DD/YYYY - h:mm A") => {
  const timestamp = Date.parse(dateString);
  return moment(timestamp).format(formatString);
};

export const formatTime = (dateString) => {
  const timestamp = Date.parse(dateString);
  return moment(timestamp).format("h:mm A");
};

export const UtcToMst = (date) => {
  if (date) {
    return moment.utc(date).tz("America/Phoenix").format("YYYY-MM-DD HH:mm");
  } else {
    return null;
  }
};

export const toLocalDate = (date) => {
  console.log("🚀 ~ toLocalDate ~ date:", typeof date);
  const formattedString = moment.utc(date).format("YYYY-MM-DD");
  return moment(formattedString, "YYYY-MM-DD").toDate();
};

export const getServiceTicketUrl = (number) => {
  const currentUser = UserService.getCurrentUser();
  return `${API_URL}/${currentUser.business}/serviceticket/service_ticket_${number}.pdf`;
};

export const convertToArizonaTime = (utcDate) => {
  return moment.utc(utcDate).tz("America/Phoenix").format("YYYY-MM-DD HH:mm:ss");
};

export const getOrdinalSuffix = (day) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = day % 100;

  return day + (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]);
};
