function BlueButton({ onClicked, title }) {
  return (
    <div
      onClick={onClicked}
      className="cursor-pointer px-[20px] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
    >
      <span>{title}</span>
    </div>
  );
}

export default BlueButton;
