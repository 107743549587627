export const WO_TYPE_LIST = ["Service call", "Quoted job", "PM", "Parts Only"];
export const TECH_TYPE_LIST = ["Primary technician", "Technician"];
export const paymentItems = ["Acct Credit", "Reimbursed", "Company CC"];
export const ITEMS_PER_PAGE = 20;

export const WOStatusList = [
  { name: "Requested", value: 0 },
  { name: "Open", value: 1 },
  { name: "Enroute", value: 2 },
  { name: "Arrived", value: 3 },
  { name: "Parts", value: 4 },
  { name: "Completed", value: 5 },
  { name: "Reviewed", value: 6 },
  { name: "Billed", value: 100 },
];

export const POStatusList = [
  { name: "Requested", value: 0 },
  { name: "Issued", value: 2 },
  { name: "Purchased", value: 3 },
  { name: "Completed", value: 4 },
];

export const dayOfWeekList = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const autoReportTypeArray = [
  { name: "Time Card", value: 20 },
  { name: "Open Work Orders", value: 0 },
  { name: "Completed Work Orders", value: 1 },
  { name: "Reviewed Work Orders", value: 2 },
  { name: "Billed Work Orders", value: 3 },
  { name: "Open Purchase Orders", value: 10 },
];

export const timeZoneArray = ["EST", "CST", "MST", "PST", "HST"];
