import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import UserSelectModal from "../../Notification/components/userselectmodal";
import { getUserNameArray } from "src/Common/function";
import Recipients from "../components/recipients";
import Technicians from "../components/technicians";
import StatusDropdown from "../components/statusdropdown";
import DayDropdown from "../components/daydropdown";
import DayOfWeekDropdown from "../components/dayofweekdropdown";
import TimePickerModal from "src/Common/timepickermodal";
import { formatDate } from "src/Common/common";
import { FaClock } from "react-icons/fa";
import moment from "moment";
import TypeDropdown from "../components/typedropdown";
import { autoReportTypeArray } from "src/Common/contants";

const AddModal = ({ shown, onCancelClicked, userArray, technicianArray, save, update, editingReport }) => {
  const [technicianSelectModalShown, setTechnicianSelectModalShown] = useState(false);
  const [userSelectModalShown, setUserSelectModalShown] = useState(false);
  const [timePickerModalShown, setTimePickerModalShown] = useState(false);

  const [type, setType] = useState(-1);
  //Technicians
  const [technicianIdList, setTechnicianIdList] = useState([]);
  const [technicianNameList, setTechnicianNameList] = useState([]);
  //Recipients
  const [recipientIdList, setRecipientIdList] = useState([]);
  const [recipientNameList, setRecipientNameList] = useState([]);
  //Is Weekly
  const [cycle, setCycle] = useState(-1); // 0: Weekly, 1: Monthly
  const [day, setDay] = useState(-1);
  //time
  const [time, setTime] = useState(null);

  useEffect(() => {
    if (editingReport) {
      setType(editingReport.type);
      setTechnicianIdList(editingReport.technicians.split(","));
      setTechnicianNameList(getUserNameArray(editingReport.technicians.split(","), technicianArray));
      setRecipientIdList(editingReport.recipients.split(","));
      setRecipientNameList(getUserNameArray(editingReport.recipients.split(","), userArray));
      setCycle(editingReport.cycle);
      setDay(editingReport.day);
      setTime(new Date(`2024-11-16T${editingReport.time}`));
    } else {
      setType(-1);
      setTechnicianIdList([]);
      setTechnicianNameList([]);
      setRecipientIdList([]);
      setRecipientNameList([]);
      setCycle(0);
      setDay(-1);
      setTime(null);
    }
  }, [shown]);

  const saveTechnicians = (users) => {
    let recipientList = [];
    for (let user of users) {
      recipientList.push(String(user.id));
    }
    setTechnicianIdList(recipientList);
    setTechnicianNameList(getUserNameArray(recipientList, userArray));
    setTechnicianSelectModalShown(false);
  };

  const saveRecipiants = (users) => {
    let recipientList = [];
    for (let user of users) {
      recipientList.push(String(user.id));
    }
    setRecipientIdList(recipientList);
    setRecipientNameList(getUserNameArray(recipientList, userArray));
    setUserSelectModalShown(false);
  };

  const saveAutoReport = () => {
    const data = {
      type: type,
      technicians: technicianIdList.join(),
      recipients: recipientIdList.join(),
      cycle: cycle,
      day: day,
      time: formatDate(time, "HH:mm:ss"),
    };
    if (editingReport) {
      update(data);
    } else {
      save(data);
    }
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
        <OutsideClickHandler onOutsideClick={() => onCancelClicked()}>
          <UserSelectModal
            shown={technicianSelectModalShown}
            userArray={technicianArray}
            selectedUserIdArray={technicianIdList}
            onCancel={() => setTechnicianSelectModalShown(false)}
            onSave={(users) => saveTechnicians(users)}
          />

          <UserSelectModal
            shown={userSelectModalShown}
            userArray={userArray}
            selectedUserIdArray={recipientIdList}
            onCancel={() => setUserSelectModalShown(false)}
            onSave={(users) => saveRecipiants(users)}
          />

          <TimePickerModal
            shown={timePickerModalShown}
            cancelClicked={() => setTimePickerModalShown(false)}
            pSelectedDate={time}
            dateSelected={(value) => {
              console.log("🚀 ~ AddModal ~ value:", value);
              setTime(value);
            }}
          />

          <div className="w-[436px] bg-white rounded-[6px] p-[18px] flex flex-col relative">
            <p className="font-OpenSansBold text-[16px] text-center text-titleColor mb-5">Add Auto-Generated Report</p>

            <div className="flex items-center mt-2">
              <p className="font-OpenSansMedium text-[13px] text-black w-[116px]">Type:</p>
              <div className="flex-1">
                <TypeDropdown type={autoReportTypeArray.find((item) => item.value === type)} setType={setType} />
              </div>
            </div>
            <div className="h-2" />
            <Technicians
              recipients={technicianNameList}
              onClick={() => {
                setTechnicianSelectModalShown(true);
              }}
            />
            <div className="h-2" />
            <Recipients
              recipients={recipientNameList}
              onClick={() => {
                setUserSelectModalShown(true);
              }}
            />

            <div className="flex items-center mt-2">
              <p className="font-OpenSansMedium text-[13px] text-black w-[116px]">Schedule:</p>
              <div className="flex-1">
                {type === 20 ? (
                  <StatusDropdown statusList={["Weekly", "Monthly", "Daily"]} statusSelected={setCycle} cycle={cycle} />
                ) : (
                  <StatusDropdown statusList={["Weekly", "Monthly"]} statusSelected={setCycle} cycle={cycle} />
                )}
              </div>
            </div>

            <div className="flex items-center mt-2">
              {!(type === 20 && cycle === 2) && (
                <>
                  <p className="font-OpenSansMedium text-[13px] text-black w-[116px]"></p>
                  <div className="flex-1">
                    {cycle === 1 ? (
                      <DayDropdown day={day} setDay={setDay} />
                    ) : (
                      <DayOfWeekDropdown day={day} setDay={setDay} />
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="flex items-center mt-2">
              <p className="font-OpenSansMedium text-[13px] text-black w-[116px]">Time:</p>
              <div className="flex-1 flex items-center gap-2">
                <div className="flex-auto h-[30px] border-[1px] rounded-md border-gray-200  flex items-center px-2">
                  <p className="font-OpenSansMedium text-[13px] text-black">{time && formatDate(time, "HH:mm")}</p>
                </div>
                <div className="cursor-pointer" onClick={() => setTimePickerModalShown(true)}>
                  <FaClock />
                </div>
              </div>
            </div>

            <div className="w-full flex justify-between gap-3 mt-5">
              <div
                onClick={onCancelClicked}
                className="cursor-pointer w-[50%] h-[30px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-inputFieldTextColor border-borderColor border-[1px]"
              >
                CANCEL
              </div>

              <div
                onClick={saveAutoReport}
                className="cursor-pointer w-[50%] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
              >
                SAVE
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default AddModal;
