import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { filter } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

const VendorDropDown = ({ options, defaultOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleVendorClick = (option) => {
    setIsOpen(false);
    onSelect(option);
    setKeyword("");
  };

  useEffect(() => {
    console.log("🚀 ~ VendorDropDown ~ options:", options);
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (keyword.length === 0) {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(options.filter((option) => option.toLowerCase().includes(keyword.toLowerCase())));
    }
  }, [keyword]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className="relative w-full">
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="cursor-pointer w-full flex items-center h-[30px] justify-between px-4 py-2 border rounded-[6px] text-gray-800 focus:outline-none focus:border-blue-500"
        >
          <span className="font-OpenSansRegular text-[400] text-[12px] text-titleColor truncate">{defaultOption}</span>
          <FiChevronDown className="ml-2" size={15} />
        </div>

        {isOpen && (
          <ul className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg max-h-[200px] overflow-y-auto">
            <div className="w-full h-[30px] border-solid border-b-[1px]  flex items-center justify-center px-[15px]">
              <input
                value={keyword}
                type="text"
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Search..."
              />
            </div>
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleVendorClick(option)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200 font-OpenSansRegular text-[12px]"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default VendorDropDown;
