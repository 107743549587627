import { Puff, Oval } from "react-loader-spinner";
import { getImageUrl } from "src/Common/common";

function ServiceTicketButton({ clicked, isLoading }) {
  return (
    <div
      onClick={!isLoading && clicked}
      className="w-[120px] h-[30px] rounded-[6px] border-solid border-[1px] border-borderColor flex items-center justify-center cursor-pointer relative"
    >
      {isLoading ? (
        <div className="flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Oval
            height={20}
            width={20}
            strokeWidth={4}
            color="#010D3D"
            secondaryColor="#80869E"
            ariaLabel="oval-loading"
            visible={true}
          />
        </div>
      ) : (
        <div className="flex gap-1">
          <img className="w-[18px] h-[18px]" src={getImageUrl("/images/common/view.png")} />
          <p className="font-OpenSansRegular font-[600] text-[10px] text-titleColor">SERVICE TICKET</p>
        </div>
      )}
    </div>
  );
}

export default ServiceTicketButton;
