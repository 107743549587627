import React, { useState, useEffect } from "react";
import CreateButton from "src/Components/CreateButton";
import { isAdmin, handleError, getUserNameArray } from "src/Common/function";
import BranchBar from "src/Components/branchbar/BranchBar";
import AddModal from "./AddModal";
import { useSelector } from "react-redux";
import UserService from "src/Services/API/user.service";
import ReportService from "src/Services/API/report.service";
import ReportItem from "./ReportItem";
import { dayOfWeekList, autoReportTypeArray } from "src/Common/contants";
import { getOrdinalSuffix } from "src/Common/common";

const AutoReport = () => {
  const branchId = useSelector((state) => state.Branch.branch);
  const currentUser = UserService.getCurrentUser();
  const [technicianArray, setTechnicianArray] = useState([]);
  const [userArray, setUserArray] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [reportArray, setReportArray] = useState([]);
  const [reportResponse, setReportResponse] = useState(null);

  //edit
  const [editingReport, setEditingReport] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all users and technicians concurrently
        const [allUsersResponse, techniciansResponse, managersResponse] = await Promise.all([
          UserService.getAllUsers(branchId),
          UserService.getUsersByRoleWithBranchId("Technician", branchId),
          UserService.getUsersByRoleWithBranchId("Manager", branchId),
        ]);

        // Check if both requests were successful
        if (allUsersResponse.status === 200) {
          setUserArray(allUsersResponse.data);
          console.log("🚀 All Users:", allUsersResponse.data);
        }

        let tempTechnicians = [];
        if (techniciansResponse.status === 200) {
          tempTechnicians = techniciansResponse.data;
        }

        if (managersResponse.status === 200) {
          tempTechnicians = [...tempTechnicians, ...managersResponse.data];
        }
        setTechnicianArray(tempTechnicians);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();

    // Fetch reports after users and technicians are set
    fetchReports();
  }, [branchId]);

  useEffect(() => {
    fetchReports();
  }, [userArray, technicianArray]);

  const fetchReports = async () => {
    const reportsResponse = await ReportService.getAll(branchId);
    if (reportsResponse.status === 200) {
      const reports = reportsResponse.data.map((report) => ({
        type:
          report.type === 20 && report.cycle === 2
            ? "Daily Report"
            : autoReportTypeArray.find((type) => type.value === report.type).name,
        id: report.id,
        technicians: getUserNameArray(report.technicians.split(","), technicianArray),
        recipients: getUserNameArray(report.recipients.split(","), userArray),
        schedule:
          report.cycle === 0
            ? `Every ${dayOfWeekList[report.day]} at ${report.time.slice(0, -3)}`
            : report.cycle === 1
            ? `Every ${getOrdinalSuffix(report.day)} at ${report.time.slice(0, -3)}`
            : `Everyday at ${report.time.slice(0, -3)}`,
      }));

      setReportArray(reports);
      setReportResponse(reportsResponse.data);
    }
  };

  const save = (data) => {
    console.log("🚀 ~ save ~ data:", data);
    if (isAdmin()) {
      data["branch"] = branchId;
    } else {
      data["branch"] = currentUser.branches[0].id;
    }
    ReportService.create(data)
      .then((response) => {
        console.log("🚀 ~ save ~ response:", response);
        setShowModal(false);
        fetchReports();
      })
      .catch((error) => {
        console.log("🚀 ~ ReportService.create ~ error:", error);
      });
  };

  const deleteReport = (index) => {
    console.log(reportArray[index]);
    ReportService.deleteReport(reportArray[index].id)
      .then((response) => {
        console.log("🚀 ~ save ~ response:", response);
        fetchReports();
      })
      .catch((error) => {
        console.log("🚀 ~ ReportService.create ~ error:", error);
      });
  };

  const editReport = (index) => {
    setEditingReport(reportResponse[index]);
    setShowModal(true);
  };

  const update = (data) => {
    ReportService.update(editingReport.id, data)
      .then((response) => {
        console.log("🚀 ~ update ~ response:", response);
        setShowModal(false);
        fetchReports();
      })
      .catch((error) => {
        console.log("🚀 ~ ReportService.update ~ error:", error);
      });
  };

  return (
    <div>
      <AddModal
        shown={showModal}
        onCancelClicked={() => setShowModal(false)}
        userArray={userArray}
        technicianArray={technicianArray}
        save={save}
        update={update}
        editingReport={editingReport}
      />
      {/* <div className="px-7 py-2">{isAdmin() && <BranchBar allEnabled={false} />}</div> */}
      <div className="flex items-center gap-3 w-full justify-end">
        <CreateButton
          title={"ADD"}
          onClicked={() => {
            setShowModal(true);
            setEditingReport(null);
          }}
        />
      </div>
      <div className="flex flex-col gap-4 ">
        {reportArray.map((report, index) => (
          <div key={index}>
            <ReportItem report={report} index={index} onEditClicked={editReport} onDeleteClicked={deleteReport} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoReport;
