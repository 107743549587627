import React, { useEffect } from "react";
import { limitString } from "src/Common/function";
import { getImageUrl } from "src/Common/common";
import { autoReportTypeArray } from "src/Common/contants";

const ReportItem = ({ report, index, onEditClicked, onDeleteClicked }) => {
  return (
    <div className="flex flex-col rounded-md shadow-lg p-3 w-[500px] relative">
      <div className="flex items-center gap-1">
        <p className="font-OpenSansMedium text-[13px] text-black w-[100px] ">Type:</p>
        <p className="font-OpenSansMedium text-[13px] text-black">{report.type}</p>
      </div>
      <div className="flex items-center gap-1">
        <p className="font-OpenSansMedium text-[13px] text-black w-[100px] ">Technicians:</p>
        <p className="font-OpenSansMedium text-[13px] text-black">{limitString(report.technicians, 40)}</p>
      </div>
      <div className="flex items-center gap-1">
        <p className="font-OpenSansMedium text-[13px] text-black w-[100px] ">Recipients:</p>
        <p className="font-OpenSansMedium text-[13px] text-black">{limitString(report.recipients, 40)}</p>
      </div>
      <div className="flex items-center gap-1">
        <p className="font-OpenSansMedium text-[13px] text-black w-[100px] ">Schedule:</p>
        <p className="font-OpenSansMedium text-[13px] text-black">{report.schedule}</p>
      </div>
      <div className="flex items-center py-1 justify-between absolute gap-2 p-1 right-1 bottom-1">
        <img
          onClick={() => {
            onEditClicked(index);
          }}
          className="w-[14px] cursor-pointer"
          src={getImageUrl("/images/common/edit.png")}
        />
        <img
          onClick={() => {
            onDeleteClicked(index);
          }}
          className=" w-[14px] cursor-pointer"
          src={getImageUrl("/images/common/black_trash.png")}
        />
      </div>
    </div>
  );
};

export default ReportItem;
